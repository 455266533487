package com.backit.shared.types.host.columnspecs

import com.backit.shared.types.host.utils.generateUuid

@JsExport
fun LongColumnSpec(
    id: String = generateUuid(),
    name: String,
    nullable: Boolean = false,
    default: Int? = null,
): LongColumnSpec = LongColumnSpec(
    id = id,
    name = name,
    nullable = nullable,
    default = default?.toLong(),
)
