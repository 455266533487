package com.backit.shared.types.host.endpoint

import com.backit.shared.types.host.endpoint.EndpointParamSpec.Companion.SERIAL_NAME_INTEGER
import com.backit.shared.types.host.endpoint.EndpointParamSpec.Companion.SERIAL_NAME_STRING
import com.backit.shared.types.host.utils.generateUuid
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@JsExport
@Serializable
data class EndpointParamSpec(
    val id: String = generateUuid(),
    val name: String,
    val type: EndpointParamType,
) {
    companion object {
        const val SERIAL_NAME_STRING = "string"
        const val SERIAL_NAME_INTEGER = "integer"
    }
}

@JsExport
@Serializable
enum class EndpointParamType(val serialName: String) {
    @SerialName(SERIAL_NAME_STRING)
    TYPE_STRING(SERIAL_NAME_STRING),
    @SerialName(SERIAL_NAME_INTEGER)
    TYPE_INTEGER(SERIAL_NAME_INTEGER),
}
