package com.backit.shared.types.api.request

import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@Serializable
@JsExport
sealed interface ControlServerRequest : Request

@Serializable
data class HostGenerateRequest(
    val orgId: String,
    val projectId: String,
) : ControlServerRequest
