
import { Box, Typography } from '@mui/material';
import React from 'react';

export function ErrorPage() {
    return (
        <Box 
            display="flex" 
            justifyContent="center" 
            alignItems="center" 
            height="100vh" 
            textAlign="center"
        >
            <Typography variant="h4">page not found.</Typography>
        </Box>
    );
}