import { Button, Divider, Grid2 as Grid, Menu, MenuItem, Select, Stack, Table, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import { ProjectContext } from "pages/project/ProjectContext";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { ChangeTypes, ModelTypes } from "types/SharedTypes"

export interface EndpointSpecEditorProps {
  projectId: string,
  endpointSpec: ModelTypes.endpoint.EndpointSpec,
  applyChange: (spec: ChangeTypes.Change.EndpointSpecChange) => void,
}

export function EndpointSpecEditor(
  props: EndpointSpecEditorProps,
) {
  const parameterList = props.endpointSpec.params.asJsReadonlyArrayView().map((paramSpec) => {
    return (
      <Grid container key={paramSpec.id}>
        <Grid size={6}>
          <Select fullWidth value={paramSpec.type.name} onChange={(e) => {
            var value: string | null = e.target.value;
            if (value === "") {
              value = null;
            }
            props.applyChange(
              new ChangeTypes.Change.EndpointSpecChange.EndpointParamSpecChange.EndpointParamTypeChange(
                props.projectId,
                props.endpointSpec.id,
                paramSpec.id,
                ModelTypes.endpoint.EndpointParamType.valueOf(value!),
              )
            )
          }}>
            {
              ModelTypes.endpoint.EndpointParamType.values().map((endpointParamType: ModelTypes.endpoint.EndpointParamType) => {
                return (
                  <MenuItem value={endpointParamType.name} key={endpointParamType.serialName}>
                    {endpointParamType.serialName}
                  </MenuItem>
                )
              })
            }
          </Select>
        </Grid>
        <Grid size={6}>
          <TextField
            fullWidth
            value={paramSpec.name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              props.applyChange(
                new ChangeTypes.Change.EndpointSpecChange.EndpointParamSpecChange.EndpointParamNameChange(
                  props.projectId,
                  props.endpointSpec.id,
                  paramSpec.id,
                  event.target.value,
                )
              )
            }}
          />
        </Grid>
      </Grid>
    )
  })

  return (
    <Stack>
      <Typography variant="caption">Path</Typography>
      <TextField
        value={props.endpointSpec.path}
        type="text"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props.applyChange(
            new ChangeTypes.Change.EndpointSpecChange.EndpointPathChange(
              props.projectId,
              props.endpointSpec.id,
              event.target.value,
            )
          )
        }}
      />
      <Typography variant="caption">Parameters</Typography>
      {parameterList}

      <Button variant="outlined" onClick={() => {
        props.applyChange(
          new ChangeTypes.Change.EndpointSpecChange.EndpointAddParamChange(
            props.projectId,
            props.endpointSpec.id,
            new ModelTypes.endpoint.EndpointParamSpec(undefined, "param", ModelTypes.endpoint.EndpointParamType.TYPE_STRING),
          )
        )
      }}>+ Parameter</Button>

      <Typography variant="caption">Source</Typography>
      <SourcePicker endpointId={props.endpointSpec.id} selectionSpec={props.endpointSpec.selection} applyChange={props.applyChange} />

      <Typography variant="caption">Filter</Typography>
      {props.endpointSpec.filter ? (
        <FilterBuilder endpointParams={props.endpointSpec.params.asJsReadonlyArrayView()} filterSpec={props.endpointSpec.filter} setFilterSpec={(spec) => {
          props.applyChange(
            new ChangeTypes.Change.EndpointSpecChange.EndpointFilterChange(
              props.projectId,
              props.endpointSpec.id,
              spec,
            )
          )
        }} onDelete={() => {
          props.applyChange(
            new ChangeTypes.Change.EndpointSpecChange.EndpointFilterChange(
              props.projectId,
              props.endpointSpec.id,
              null,
            )
          )
        }} />) : (
        <Button variant="outlined" onClick={() => {
          props.applyChange(
            new ChangeTypes.Change.EndpointSpecChange.EndpointFilterChange(
              props.projectId,
              props.endpointSpec.id,
              new ModelTypes.endpoint.EqualsEndpointFilterSpec("", "", new ModelTypes.endpoint.StringFilterValueSpec("")),
            )
          )
        }}>+ Filter</Button>
      )}
    </Stack>
  )
}

interface SourcePickerProps {
  endpointId: string,
  selectionSpec: ModelTypes.endpoint.EndpointSelectionSpec,
  applyChange: (change: ChangeTypes.Change.EndpointSpecChange) => void,
}

function SourcePicker(
  props: SourcePickerProps,
) {
  const projectContext = useContext(ProjectContext);
  const project = projectContext?.displaySpec;
  if (!project) return;

  const setSpec: (newSpec: ModelTypes.endpoint.EndpointSelectionSpec) => void = (newSpec) => {
    props.applyChange(
      new ChangeTypes.Change.EndpointSpecChange.EndpointSelectionChange(
        project.id,
        props.endpointId,
        newSpec,
      )
    )
  }

  let specType;
  let builder;
  if (props.selectionSpec instanceof ModelTypes.endpoint.TableEndpointSelectionSpec) {
    specType = "table";
    builder = (
      <TableSelectionBuilder selectionSpec={props.selectionSpec} setSelectionSpec={setSpec} />
    )
  } else if (props.selectionSpec instanceof ModelTypes.endpoint.JoinEndpointSelectionSpec) {
    specType = "join";
    builder = (
      <JoinSelectionBuilder selectionSpec={props.selectionSpec} setSelectionSpec={setSpec} />
    )
  }

  const models = project.hostSpec.models.asJsReadonlyArrayView()

  return (
    <Stack>
      <Select value={specType} onChange={(event) => {
        let newSpec: ModelTypes.endpoint.EndpointSelectionSpec | null = null;
        if (event.target.value == "table") {
          newSpec = new ModelTypes.endpoint.TableEndpointSelectionSpec(models[0].name);
        } else if (event.target.value == "join") {
          newSpec = new ModelTypes.endpoint.JoinEndpointSelectionSpec(
            new ModelTypes.endpoint.TableEndpointSelectionSpec(models[0].name),
            new ModelTypes.endpoint.TableEndpointSelectionSpec(models[0].name),
            new ModelTypes.endpoint.JoinEndpointSelectionSpec.JoinColumnSelector(models[0].name, "id"),
            new ModelTypes.endpoint.JoinEndpointSelectionSpec.JoinColumnSelector(models[0].name, "id"),
            ModelTypes.endpoint.JoinEndpointSelectionSpec.JoinType.INNER,
          );
        }
        if (newSpec) {
          setSpec(newSpec);
        }
      }}>
        <MenuItem value="table">Table</MenuItem>
        <MenuItem value="join">Join</MenuItem>
      </Select>
      {builder}
    </Stack>
  )
}

interface TableSelectionBuilderProps {
  selectionSpec: ModelTypes.endpoint.TableEndpointSelectionSpec,
  setSelectionSpec: (spec: ModelTypes.endpoint.TableEndpointSelectionSpec) => void,
}

function TableSelectionBuilder(
  { selectionSpec, setSelectionSpec }: TableSelectionBuilderProps,
) {
  return (
    <Stack>
      <Typography variant="caption">Table</Typography>
      <TablePicker table={selectionSpec.name} setTable={(table) => {
        setSelectionSpec(new ModelTypes.endpoint.TableEndpointSelectionSpec(table));
      }} />
    </Stack>
  )
}

function TablePicker(
  {
    table,
    setTable,
  }: {
    table: string,
    setTable: (table: string) => void,
  }
) {
  const projectContext = useContext(ProjectContext);
  const project = projectContext?.displaySpec;

  if (project) {
    return (<Select
      value={table}
      onChange={(event) => {
        setTable(event.target.value);
      }}>
      {
        project.hostSpec.models.asJsReadonlyArrayView().map((modelSpec) => {
          return (
            <MenuItem value={modelSpec.name} key={modelSpec.id}>
              {modelSpec.name}
            </MenuItem>
          )
        })
      }
    </Select>)
  }
}

function ColumnPicker(
  {
    table,
    column,
    setColumn,
  }: {
    table: string,
    column: string,
    setColumn: (column: string) => void,
  }
) {
  const projectContext = useContext(ProjectContext);
  const project = projectContext?.displaySpec;


  if (project) {
    let modelsById: { [key: string]: ModelTypes.ModelSpec } = {}
    project.hostSpec.models.asJsReadonlyArrayView().forEach((model) => {
      modelsById[model.name] = model;
    })
    return (<Select
      value={column}
      onChange={(event) => {
        setColumn(event.target.value);
      }}>
      <MenuItem value="id">id</MenuItem>
      {
        modelsById[table]?.columns?.asJsReadonlyArrayView()?.map((columnSpec) => {
          return (
            <MenuItem value={columnSpec.name} key={columnSpec.id}>
              {columnSpec.name}
            </MenuItem>
          )
        })
      }
    </Select>)
  }
}

interface JoinSelectionBuilderProps {
  selectionSpec: ModelTypes.endpoint.JoinEndpointSelectionSpec,
  setSelectionSpec: (spec: ModelTypes.endpoint.JoinEndpointSelectionSpec) => void,
}

function doesJoinTypeReturnAllLeft(joinType: ModelTypes.endpoint.JoinEndpointSelectionSpec.JoinType) {
  return joinType === ModelTypes.endpoint.JoinEndpointSelectionSpec.JoinType.LEFT || joinType === ModelTypes.endpoint.JoinEndpointSelectionSpec.JoinType.FULL;
}
function doesJoinTypeReturnAllRight(joinType: ModelTypes.endpoint.JoinEndpointSelectionSpec.JoinType) {
  return joinType === ModelTypes.endpoint.JoinEndpointSelectionSpec.JoinType.RIGHT || joinType === ModelTypes.endpoint.JoinEndpointSelectionSpec.JoinType.FULL;
}

function JoinSelectionBuilder(
  { selectionSpec, setSelectionSpec }: JoinSelectionBuilderProps,
) {
  const projectContext = useContext(ProjectContext);
  const project = projectContext?.displaySpec;
  const [leftReturnAll, setLeftReturnAll] = useState(doesJoinTypeReturnAllLeft(selectionSpec.joinType));
  const [rightReturnAll, setRightReturnAll] = useState(doesJoinTypeReturnAllLeft(selectionSpec.joinType));

  useEffect(() => {
    let joinType: ModelTypes.endpoint.JoinEndpointSelectionSpec.JoinType = ModelTypes.endpoint.JoinEndpointSelectionSpec.JoinType.INNER;
    if (leftReturnAll && rightReturnAll) {
      joinType = ModelTypes.endpoint.JoinEndpointSelectionSpec.JoinType.FULL;
    } else if (leftReturnAll) {
      joinType = ModelTypes.endpoint.JoinEndpointSelectionSpec.JoinType.LEFT;
    } else if (rightReturnAll) {
      joinType = ModelTypes.endpoint.JoinEndpointSelectionSpec.JoinType.RIGHT;
    }
    setSelectionSpec(selectionSpec.copy(undefined, undefined, undefined, undefined, joinType));
  }, [leftReturnAll, rightReturnAll])
  if (project) {
    return (
      <Grid container>
        <Grid size={5}>
          <Stack>
            <Typography variant="caption">Left Table</Typography>
            <TablePicker
              table={(selectionSpec.left as ModelTypes.endpoint.TableEndpointSelectionSpec).name}
              setTable={(table) => {
                setSelectionSpec(selectionSpec.copy(new ModelTypes.endpoint.TableEndpointSelectionSpec(table)));
              }} />
            <ColumnPicker
              table={(selectionSpec.left as ModelTypes.endpoint.TableEndpointSelectionSpec).name}
              column={(selectionSpec.leftSelector as ModelTypes.endpoint.JoinEndpointSelectionSpec.JoinColumnSelector).column}
              setColumn={(column) => {
                setSelectionSpec(selectionSpec.copy(undefined, undefined, new ModelTypes.endpoint.JoinEndpointSelectionSpec.JoinColumnSelector((selectionSpec.left as ModelTypes.endpoint.TableEndpointSelectionSpec).name, column)));
              }} />

            <Typography variant="caption">Return rows</Typography>
            <ToggleButtonGroup
              color="primary"
              value={leftReturnAll}
              size="small"
              exclusive
              onChange={(
                _event: React.MouseEvent<HTMLElement>,
                value: boolean | null
              ) => {
                if (value !== null) {
                  setLeftReturnAll(value);
                }
              }}
            >
              <ToggleButton value={false}>Matching</ToggleButton>
              <ToggleButton value={true}>All rows</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>
        <Grid size={2} alignContent="center">
          <Typography variant="h6" sx={{
            width: "100%",
            textAlign: "center",
          }}>=</Typography>
        </Grid>
        <Grid size={5}>
          <Stack>
            <Typography variant="caption">Right Table</Typography>
            <TablePicker
              table={(selectionSpec.right as ModelTypes.endpoint.TableEndpointSelectionSpec).name}
              setTable={(table) => {
                setSelectionSpec(selectionSpec.copy(undefined, new ModelTypes.endpoint.TableEndpointSelectionSpec(table)));
              }} />
            <ColumnPicker
              table={(selectionSpec.right as ModelTypes.endpoint.TableEndpointSelectionSpec).name}
              column={(selectionSpec.rightSelector as ModelTypes.endpoint.JoinEndpointSelectionSpec.JoinColumnSelector).column}
              setColumn={(column) => {
                setSelectionSpec(selectionSpec.copy(undefined, undefined, undefined, new ModelTypes.endpoint.JoinEndpointSelectionSpec.JoinColumnSelector((selectionSpec.right as ModelTypes.endpoint.TableEndpointSelectionSpec).name, column)));
              }} />

            <Typography variant="caption">Return rows</Typography>
            <ToggleButtonGroup
              color="primary"
              value={rightReturnAll}
              size="small"
              exclusive
              onChange={(
                _event: React.MouseEvent<HTMLElement>,
                value: boolean | null
              ) => {
                if (value !== null) {
                  setRightReturnAll(value);
                }
              }}
            >
              <ToggleButton value={false}>Matching</ToggleButton>
              <ToggleButton value={true}>All rows</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>
      </Grid >
    )
  }
}

function FilterBuilder({
  endpointParams,
  filterSpec,
  setFilterSpec,
  onDelete,
}: {
  endpointParams: readonly ModelTypes.endpoint.EndpointParamSpec[],
  filterSpec: ModelTypes.endpoint.EndpointFilterSpec,
  setFilterSpec: (spec: ModelTypes.endpoint.EndpointFilterSpec) => void,
  onDelete: () => void,
}) {
  let filterType;
  let builder;
  if (filterSpec instanceof ModelTypes.endpoint.OrEndpointFilterSpec) {
    filterType = "or";
    builder = (
      <Grid container alignItems={"center"}>
        <Grid size={11} offset={1}>
          <Stack spacing={2} divider={<Divider />}>
            {filterSpec.filters.asJsReadonlyArrayView().map((filter, index) => {
              return (
                <FilterBuilder key={index} endpointParams={endpointParams} filterSpec={filter} setFilterSpec={(newSpec) => {
                  let newFilters = filterSpec.filters.asJsReadonlyArrayView().slice();
                  newFilters[index] = newSpec;
                  setFilterSpec(new ModelTypes.endpoint.OrEndpointFilterSpec(ModelTypes.toList(newFilters)));
                }} onDelete={() => {
                  let newFilters = filterSpec.filters.asJsReadonlyArrayView().slice();
                  newFilters.splice(index, 1);
                  setFilterSpec(new ModelTypes.endpoint.OrEndpointFilterSpec(ModelTypes.toList(newFilters)));
                }} />
              )
            })}
            <Button variant="outlined" onClick={() => {
              setFilterSpec(new ModelTypes.endpoint.OrEndpointFilterSpec(
                ModelTypes.toList([
                  ...filterSpec.filters.asJsReadonlyArrayView(),
                  new ModelTypes.endpoint.EqualsEndpointFilterSpec("", "", new ModelTypes.endpoint.StringFilterValueSpec(""))
                ])
              ));
            }}>+ Filter</Button>
          </Stack>
        </Grid>
      </Grid>
    )
  } else if (filterSpec instanceof ModelTypes.endpoint.AndEndpointFilterSpec) {
    filterType = "and";
    builder = (
      <Grid container>
        <Grid size={11} offset={1}>
          <Stack spacing={2} divider={<Divider />}>
            {filterSpec.filters.asJsReadonlyArrayView().map((filter, index) => {
              return (
                <FilterBuilder key={index} endpointParams={endpointParams} filterSpec={filter} setFilterSpec={(newSpec) => {
                  let newFilters = filterSpec.filters.asJsReadonlyArrayView().slice();
                  newFilters[index] = newSpec;
                  setFilterSpec(new ModelTypes.endpoint.AndEndpointFilterSpec(ModelTypes.toList(newFilters)));
                }} onDelete={() => {
                  let newFilters = filterSpec.filters.asJsReadonlyArrayView().slice();
                  newFilters.splice(index, 1);
                  setFilterSpec(new ModelTypes.endpoint.AndEndpointFilterSpec(ModelTypes.toList(newFilters)));
                }} />
              )
            })}
            <Button variant="outlined" onClick={() => {
              setFilterSpec(new ModelTypes.endpoint.AndEndpointFilterSpec(
                ModelTypes.toList([
                  ...filterSpec.filters.asJsReadonlyArrayView(),
                  new ModelTypes.endpoint.EqualsEndpointFilterSpec("", "", new ModelTypes.endpoint.StringFilterValueSpec(""))
                ])
              ));
            }}>+ Filter</Button>
          </Stack>
        </Grid>
      </Grid>
    )
  } else if (filterSpec instanceof ModelTypes.endpoint.EqualsEndpointFilterSpec) {
    filterType = "equals";
    builder = (
      <EqualsFilterSpecBuiler endpointParams={endpointParams} filterSpec={filterSpec} setFilterSpec={setFilterSpec} />
    )
  }

  return (
    <Stack>
      <Select value={filterType} onChange={(event) => {
        let newSpec: ModelTypes.endpoint.EndpointFilterSpec | null = null;
        if (event.target.value == "or") {
          newSpec = new ModelTypes.endpoint.OrEndpointFilterSpec(ModelTypes.toList([]));
        } else if (event.target.value == "and") {
          newSpec = new ModelTypes.endpoint.AndEndpointFilterSpec(ModelTypes.toList([]));
        } else if (event.target.value == "equals") {
          newSpec = new ModelTypes.endpoint.EqualsEndpointFilterSpec("", "", new ModelTypes.endpoint.StringFilterValueSpec(""));
        } else if (event.target.value == "delete") {
          onDelete();
        }
        if (newSpec) {
          setFilterSpec(newSpec);
        }
      }}>
        <MenuItem value="or">OR</MenuItem>
        <MenuItem value="and">AND</MenuItem>
        <MenuItem value="equals">EQUALS</MenuItem>
        <MenuItem value="delete">Delete Filter</MenuItem>
      </Select>
      {builder}
    </Stack>

  )
}

function EqualsFilterSpecBuiler({
  endpointParams,
  filterSpec,
  setFilterSpec
}: {
  endpointParams: readonly ModelTypes.endpoint.EndpointParamSpec[],
  filterSpec: ModelTypes.endpoint.EqualsEndpointFilterSpec,
  setFilterSpec: (spec: ModelTypes.endpoint.EqualsEndpointFilterSpec) => void,
}) {
  const [table, setTable] = useState(filterSpec.table);
  const [column, setColumn] = useState(filterSpec.column);
  const [value, setValue] = useState(filterSpec.value);

  useEffect(() => {
    setFilterSpec(new ModelTypes.endpoint.EqualsEndpointFilterSpec(table, column, value));
  }, [table, column, value]);

  return (
    <Grid container alignItems={"center"}>
      <Grid size={5}>
        <Stack>
          <Typography variant="caption">Table</Typography>
          <TablePicker table={table} setTable={setTable} />
          <ColumnPicker table={table} column={column} setColumn={setColumn} />
        </Stack>
      </Grid>
      <Grid size={2} alignContent="center">
        <Typography variant="h6" sx={{
          width: "100%",
          textAlign: "center",
        }}>=</Typography>
      </Grid>
      <Grid size={5}>
        <Stack>
          <Typography variant="caption">Value</Typography>
          <FilterValueBuilder endpointParams={endpointParams} filterValue={value} setFilterValue={setValue} />
        </Stack>
      </Grid>
    </Grid>
  )
}

function FilterValueBuilder({
  endpointParams,
  filterValue,
  setFilterValue,
}: {
  endpointParams: readonly ModelTypes.endpoint.EndpointParamSpec[],
  filterValue: ModelTypes.endpoint.FilterValueSpec,
  setFilterValue: (value: ModelTypes.endpoint.FilterValueSpec) => void,
}) {
  let type;
  let builder;
  if (filterValue instanceof ModelTypes.endpoint.StringFilterValueSpec) {
    type = "string";
    builder = (
      <TextField value={filterValue.value} onChange={(event) => {
        setFilterValue(new ModelTypes.endpoint.StringFilterValueSpec(event.target.value));
      }} />
    )
  } else if (filterValue instanceof ModelTypes.endpoint.IntegerFilterValueSpec) {
    type = "integer";
    builder = (
      <TextField value={filterValue.value} type="number" onChange={(event) => {
        setFilterValue(new ModelTypes.endpoint.IntegerFilterValueSpec(parseInt(event.target.value)));
      }} />
    )
  } else if (filterValue instanceof ModelTypes.endpoint.ParamRefFilterValueSpec) {
    type = "param";
    const paramOptions = endpointParams.map((param) => {
      return (
        <MenuItem value={param.name} key={param.id}>
          {param.name}
        </MenuItem>
      )
    })
    builder = (
      <Select value={filterValue.value} onChange={(event) => {
        setFilterValue(new ModelTypes.endpoint.ParamRefFilterValueSpec(event.target.value));
      }}>
        {paramOptions}
      </Select>
    )
  }

  return (
    <Stack>
      <Select value={type} onChange={(event) => {
        let newSpec: ModelTypes.endpoint.FilterValueSpec | null = null;
        if (event.target.value == "string") {
          newSpec = new ModelTypes.endpoint.StringFilterValueSpec("");
        } else if (event.target.value == "integer") {
          newSpec = new ModelTypes.endpoint.IntegerFilterValueSpec(0);
        } else if (event.target.value == "param") {
          newSpec = new ModelTypes.endpoint.ParamRefFilterValueSpec(endpointParams[0]?.name || "");
        }
        if (newSpec) {
          setFilterValue(newSpec);
        }
      }}>
        <MenuItem value="string">String</MenuItem>
        <MenuItem value="integer">Integer</MenuItem>
        <MenuItem value="param">Parameter</MenuItem>
      </Select>
      {builder}
    </Stack>
  )
}