import { Box, IconButton, Stack, Typography } from "@mui/material";
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

export default function LoggedOutHomePage() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      textAlign="center">
      <Stack direction={"row"} spacing={2}>
        <Typography variant="h3">bend<b>scale</b></Typography>
        <Box alignContent="center">
          <IconButton href="/login" size="large"
            sx={{
              '&:hover': {
                rotate: '-45deg',
                cursor: 'pointer',
              },
              transition: '0.5s',
            }}>
            <ArrowForwardRoundedIcon
              fontSize="large"
              sx={{
                color: "black",
              }} />
          </IconButton>
        </Box>
      </Stack>
    </Box>
  );
}