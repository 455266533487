package com.backit.shared.types.host.changes.handlers

import com.backit.shared.types.host.changes.Change
import com.backit.shared.types.host.changes.Change.ColumnSpecChange.ColumnSpecNameChange
import com.backit.shared.types.host.changes.Change.ColumnSpecChange.ColumnSpecNullableChange
import com.backit.shared.types.host.columnspecs.*

fun ColumnSpec.applyColumnSpecNameChange(
    change: ColumnSpecNameChange,
): ColumnSpec {
    return when (this) {
        is BooleanColumnSpec -> copy(name = change.newName)
        is FloatColumnSpec -> copy(name = change.newName)
        is IntColumnSpec -> copy(name = change.newName)
        is LongColumnSpec -> copy(name = change.newName)
        is ReferenceColumnSpec -> copy(name = change.newName)
        is TextColumnSpec -> copy(name = change.newName)
        is VarcharColumnSpec -> copy(name = change.newName)
    }
}

fun ColumnSpec.applyColumnSpecNullableChange(
    change: ColumnSpecNullableChange,
): ColumnSpec {
    return when (this) {
        is BooleanColumnSpec -> copy(nullable = change.nullable)
        is FloatColumnSpec -> copy(nullable = change.nullable)
        is IntColumnSpec -> copy(nullable = change.nullable)
        is LongColumnSpec -> copy(nullable = change.nullable)
        is ReferenceColumnSpec -> copy(nullable = change.nullable)
        is TextColumnSpec -> copy(nullable = change.nullable)
        is VarcharColumnSpec -> copy(nullable = change.nullable)
    }
}

fun BooleanColumnSpec.applyBooleanColumnSpecDefaultChange(
    change: Change.BooleanColumnSpecChange.BooleanColumnSpecDefaultChange,
): BooleanColumnSpec {
    return copy(default = change.default)
}

fun FloatColumnSpec.applyFloatColumnSpecDefaultChange(
    change: Change.FloatColumnSpecChange.FloatColumnSpecDefaultChange,
): FloatColumnSpec {
    return copy(default = change.default)
}

fun IntColumnSpec.applyIntColumnSpecDefaultChange(
    change: Change.IntColumnSpecChange.IntColumnSpecDefaultChange,
): IntColumnSpec {
    return copy(default = change.default)
}

fun LongColumnSpec.applyLongColumnSpecDefaultChange(
    change: Change.LongColumnSpecChange.LongColumnSpecDefaultChange,
): LongColumnSpec {
    return copy(default = change.default)
}

fun ReferenceColumnSpec.applyReferenceColumnSpecDefaultChange(
    change: Change.ReferenceColumnSpecChange.ReferenceColumnSpecDefaultChange,
): ReferenceColumnSpec {
    return copy(default = change.default)
}

fun ReferenceColumnSpec.applyReferenceColumnSpecTableChange(
    change: Change.ReferenceColumnSpecChange.ReferenceColumnSpecTableChange,
): ReferenceColumnSpec {
    return copy(table = change.table)
}

fun TextColumnSpec.applyTextColumnSpecDefaultChange(
    change: Change.TextColumnSpecChange.TextColumnSpecDefaultChange,
): TextColumnSpec {
    return copy(default = change.default)
}

fun VarcharColumnSpec.applyVarCharColumnSpecDefaultChange(
    change: Change.VarCharColumnSpecChange.VarCharColumnSpecDefaultChange,
): VarcharColumnSpec {
    return copy(default = change.default)
}
