import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ThemeProvider } from '@emotion/react';
import { createTheme, CssBaseline } from '@mui/material';
import {
  createBrowserRouter,
  redirect,
  redirectDocument,
  RouterProvider,
} from "react-router-dom";
import { ErrorPage } from './components/scaffolding/ErrorPage.tsx';
import ModelSpecPage from 'pages/model/ModelSpecPage.tsx';
import EndpointListPage from 'pages/endpoint/EndpointListPage.tsx';
import ProjectRootPage from 'pages/project/ProjectRootPage.tsx';
import ProjectOverviewPage from 'pages/ProjectOverviewPage.tsx';
import ModelListPage from 'pages/model/ModelListPage.tsx';
import { ModelTypes } from 'types/SharedTypes.tsx';
import LoginPage from 'pages/LoginPage.tsx';
import { useState } from 'react';
import AppRootPage from 'pages/AppRootPage.tsx';
import EndpointSpecPage from 'pages/endpoint/EndpointSpecPage.tsx';
import LoggedOutHomePage from 'pages/LoggedOutHomePage.tsx';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h6code: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h6code?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h6code: true;
  }
}

export function App() {
  const theme = createTheme({
    palette: {
      mode: 'light',
    },
    spacing: 8,
    shape: {
      borderRadius: 12,
    },
    typography: {
      h6code: {
        fontFamily: "monospace",
        fontSize: "1em",
      },
    },
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            h6code: 'h6',
          },
        },
      },
    },
  });

  const [token, setToken] = useState<string | null>(window.localStorage.getItem("token"));

  const router = createBrowserRouter([
    {
      path: "/",
      element: <LoggedOutHomePage />,
    },
    {
      path: "/login",
      element: <LoginPage onLogin={(token) => {
        setToken(token);
        window.localStorage.setItem("token", token);
      }} />,
      loader: async () => {
        if (token) {
          return redirect("/app");
        } else {
          return null;
        }
      }
    },
    {
      path: "/app",
      loader: async () => {
        if (!token) {
          return redirect("/login");
        } else {
          return null;
        }
      },
      element: <AppRootPage token={token!!} clearToken={() => {
        window.localStorage.removeItem("token");
        window.location.href = "/";
      }}/>,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "p/:projectId",
          element: <ProjectRootPage />,
          errorElement: <ErrorPage />,
          children: [
            {
              path: "",
              loader: async ({ params }) => {
                if (ModelTypes.utils.isUuid(params.projectId ?? "")) {
                  return redirect("overview");
                } else {
                  return null;
                }
              }
            },
            {
              path: "overview",
              element:
                <ProjectOverviewPage />
              ,
            },
            {
              path: "models",
              element: <ModelListPage />
            },
            {
              path: "model/:modelId",
              element: <ModelSpecPage />
            },
            {
              path: "endpoints",
              element: <EndpointListPage />
            },
            {
              path: "endpoint/:endpointId",
              element: <EndpointSpecPage />
            },
          ]
        },
      ]
    },
  ]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

