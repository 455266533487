package com.backit.shared.types.host.columnspecs

import com.backit.shared.types.host.columnspecs.ColumnType.*
import com.backit.shared.types.host.utils.generateUuid
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@JsExport
@Serializable
sealed interface ColumnSpec {
    val id: String
    val name: String
    val nullable: Boolean
    val default: Any?

    companion object {
        const val SERIAL_NAME_BOOLEAN = "boolean"
        const val SERIAL_NAME_FLOAT = "float"
        const val SERIAL_NAME_INTEGER = "integer"
        const val SERIAL_NAME_LONG = "long"
        const val SERIAL_NAME_REFERENCE = "reference"
        const val SERIAL_NAME_TEXT = "text"
        const val SERIAL_NAME_VARCHAR = "varchar"

        fun createDefault(name: String, typeName: String): ColumnSpec {
            val id = generateUuid()
            val type = ColumnType.valueOf(typeName)
            return when (type) {
                TYPE_BOOLEAN -> BooleanColumnSpec(id, name)
                TYPE_FLOAT -> FloatColumnSpec(id, name)
                TYPE_INTEGER -> IntColumnSpec(id, name)
                TYPE_LONG -> LongColumnSpec(id, name, false, null as Long?)
                TYPE_REFERENCE -> ReferenceColumnSpec(id, name, "<table>")
                TYPE_TEXT -> TextColumnSpec(id, name)
                TYPE_VARCHAR -> VarcharColumnSpec(id, name, 255)
            }
        }
    }
}

@JsExport
@Serializable
enum class ColumnType(val serialName: String) {
    TYPE_BOOLEAN(ColumnSpec.SERIAL_NAME_BOOLEAN),
    TYPE_FLOAT(ColumnSpec.SERIAL_NAME_FLOAT),
    TYPE_INTEGER(ColumnSpec.SERIAL_NAME_INTEGER),
    TYPE_LONG(ColumnSpec.SERIAL_NAME_LONG),
    TYPE_REFERENCE(ColumnSpec.SERIAL_NAME_REFERENCE),
    TYPE_TEXT(ColumnSpec.SERIAL_NAME_TEXT),
    TYPE_VARCHAR(ColumnSpec.SERIAL_NAME_VARCHAR);
}
