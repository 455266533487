package com.backit.shared.types.host.columnspecs

import com.backit.shared.types.host.utils.generateUuid
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.js.JsExport
import kotlin.js.JsName

@JsExport
@JsName("_LongColumnSpec")
@SerialName(ColumnSpec.SERIAL_NAME_LONG)
@Serializable
data class LongColumnSpec(
    override val id: String = generateUuid(),
    override val name: String,
    override val nullable: Boolean = false,
    override val default: Long? = null,
) : ColumnSpec
