import { Outlet, useParams } from "react-router-dom";
import ProjectScaffold from "components/scaffolding/ProjectScaffold";
import { ProjectProvider } from "./ProjectContext";
import { useContext, useEffect, useState } from "react";
import { ApiTypes, ModelTypes } from "types/SharedTypes";
import { BackitApiClientContext } from "pages/AppRootPage";

export default function ProjectRootPage() {
  const { projectId } = useParams() as { projectId: string };
  const [initialProjectSpec, setInitialProjectSpec] = useState<ModelTypes.ProjectSpec | null>(null);

  const httpClient = useContext(BackitApiClientContext);
  useEffect(() => {
    httpClient?.getProject("kz-test-12345", projectId).then((config) => {
      if (config instanceof ApiTypes.response.GetProjectResponse.Success) {
        setInitialProjectSpec(config.project);
      }
    });
  }, [projectId]);

  if (initialProjectSpec) {
    return (
      <ProjectProvider initialProjectSpec={initialProjectSpec}>
        <ProjectScaffold projectId={projectId}>
          <Outlet />
        </ProjectScaffold>
      </ProjectProvider>
    );
  }
}
