package com.backit.shared.types.host.utils

import kotlin.js.JsExport
import kotlin.uuid.ExperimentalUuidApi
import kotlin.uuid.Uuid

expect fun generateUuid(): String

@JsExport
@OptIn(ExperimentalUuidApi::class)
fun isUuid(uuid: String): Boolean {
    return runCatching {
        Uuid.parse(uuid)
    }.isSuccess
}
