package com.backit.shared.types.host.utils

import kotlin.uuid.ExperimentalUuidApi
import kotlin.uuid.Uuid

@JsExport
@OptIn(ExperimentalUuidApi::class)
actual fun generateUuid(): String {
    return Uuid.random().toString()
}
