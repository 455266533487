import { TextField } from "@mui/material";
import React from "react";
import RegexExpressions from "util/RegexExpressions";

interface NameEditorProps {
  name: string;
  onNameChanged: (name: string) => void;
}

const NameEditor: React.FC<NameEditorProps> = ({ name, onNameChanged }) => {
  const isNameValid = RegexExpressions.camelCase.test(name);
  const showErrors = !isNameValid && name.length > 0;
  return (
    <TextField autoComplete="off" fullWidth variant="outlined" value={name}
      error={showErrors} helperText={showErrors ? <p>Please enter a name in <code>camelCase</code> format.</p> : ""}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onNameChanged(event.target.value);
      }}
    />
  )
}

export default NameEditor;
