import { Box, alpha } from "@mui/material";
import { PropsWithChildren, useContext } from "react";
import AppNavbar from "./AppBar.tsx";
import AppSideMenu from "./AppSideMenu.tsx";
import { ProjectContext } from "pages/project/ProjectContext.tsx";

interface ProjectScaffoldProps {
  projectId: string;
}

export default function ProjectScaffold({
  children,
  projectId,
}: PropsWithChildren<ProjectScaffoldProps>) {
  const projectContext = useContext(ProjectContext);
  
  return (
    <Box sx={{ display: 'flex' }}>
      <AppSideMenu projectId={projectId} />
      <Box
        component="main"
        sx={(theme) => ({
          flexGrow: 1,
          backgroundColor: alpha(theme.palette.background.default, 1),
          overflow: 'visible',
        })}
      >
        <AppNavbar />
        {children}
      </Box>
    </Box>
  )
}