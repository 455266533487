package com.backit.shared.types.core

import com.backit.shared.types.host.ProjectSpec
import com.backit.shared.types.host.utils.generateUuid
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@JsExport
@Serializable
data class Organization(
    val id: String = generateUuid(),
    val name: String,
    val projects: List<ProjectSpec>,
)
