package com.backit.shared.types.api.response

import com.backit.shared.types.host.ProjectSpec
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import kotlin.js.JsExport

@Serializable
sealed interface ConfigServerResponse : Response

@JsExport
@Serializable
data class DeployResponse(
    val orgId: String,
    val projectId: String,
) : ConfigServerResponse

@JsExport
@Serializable
sealed class GetProjectResponse : ConfigServerResponse {
    @Serializable
    data class Error(val message: String) : GetProjectResponse()

    @Serializable
    data class Success(val project: ProjectSpec) : GetProjectResponse()
}

@JsExport
fun deserializeGetProjectResponse(getProjectResponse: String): GetProjectResponse {
    return Json.decodeFromString(getProjectResponse)
}
