import { ApiTypes, ModelTypes } from "types/SharedTypes";

class BackitApiClient {
  private readonly token: string;
  private readonly baseUrl: string;
  private readonly onUnauthorized: () => void;

  constructor(
    baseUrl: string,
    token: string,
    onUnauthorized: () => void,
  ) {
    this.token = token;
    this.baseUrl = baseUrl;
    this.onUnauthorized = onUnauthorized;
  }

  private async makeRequest(endpoint: string, options: RequestInit = {}): Promise<Response> {
    const headers = new Headers(options.headers || {});
    headers.set('Authorization', `Bearer ${this.token}`);


    const response = await fetch(this.baseUrl + endpoint, {
      ...options,
      headers,
    });

    if (response.status === 401) {
      this.onUnauthorized();
    }

    return response;
  }

  async getTestRoute(): Promise<string | null> {
    const response = await this.makeRequest('/api/auth');
    if (response.ok) {
      return response.text();
    } else {
      return null;
    }
  }

  async postDeploy(deployRequest: ApiTypes.request.DeployRequest): Promise<string | null> {
    const response = await this.makeRequest('/api/deploy', {
      method: 'POST',
      body: ApiTypes.request.serialize(deployRequest),
      headers: {
        'content-type': 'application/json'
      }
    });
    if (response.ok) {
      return response.text();
    } else {
      return null;
    }
  }

  async getProject(orgId: string, projectId: string): Promise<ApiTypes.response.GetProjectResponse | null> {
    const response = await this.makeRequest('/api/getProject',
      {
        method: "POST",
        body: ApiTypes.request.serialize(new ApiTypes.request.GetProjectRequest(orgId, projectId)),
        headers: {
          'content-type': 'application/json'
        }
      }
    );
    if (response.ok) {
      return ApiTypes.response.deserializeGetProjectResponse(await response.text());
    } else {
      return null;
    }
  }

  async getDeployStatus(orgId: string, projectId: string): Promise<string | null> {
    const response = await this.makeRequest('/api/getDeployStatus',
      {
        method: "POST",
        body: ApiTypes.request.serialize(new ApiTypes.request.GetDeployStatusRequest(orgId, projectId)),
        headers: {
          'content-type': 'application/json'
        }
      }
    );
    if (response.ok) {
      return response.text();
    } else {
      return null;
    }
  }
}

export default BackitApiClient;
