package com.backit.shared.types.host.endpoint

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@JsExport
@Serializable
sealed interface FilterValueSpec

@JsExport
@SerialName("intLiteral")
@Serializable
data class IntegerFilterValueSpec(
    val value: Int,
) : FilterValueSpec

@JsExport
@SerialName("stringLiteral")
@Serializable
data class StringFilterValueSpec(
    val value: String,
) : FilterValueSpec

@JsExport
@SerialName("param")
@Serializable
data class ParamRefFilterValueSpec(
    val value: String,
) : FilterValueSpec
