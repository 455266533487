import { Grid2 as Grid, Typography, Box, Chip } from "@mui/material";
import React from "react";
import { ModelTypes } from "types/SharedTypes.tsx";
import NullableDisplay from "./NullablePropertyDisplay.tsx";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

export default function FloatColumnSpecRow({
  columnSpec,
}: {
  columnSpec: ModelTypes.columnspecs.FloatColumnSpec,
}) {
  return (
    <Grid container spacing={2}>
      <Grid size={4}>
        <Typography variant="overline">Float</Typography>
        <Typography variant="h6code" component='h6'>.{columnSpec.name}</Typography>
      </Grid>
      <Grid size={2}>
        <NullableDisplay columnSpec={columnSpec} />
      </Grid>
      <Grid size={2}>
        <Typography variant="caption">Default Value</Typography>
        <Box py={1}>
          {
            columnSpec.default == null ?
              (<Chip size="small" color="info" label="No Default Value" variant="outlined" icon={<CloseIcon />} />) :
              (<Chip size="small" color="info" label={`Default Value: ${columnSpec.default}`} icon={<CheckIcon />} />)
          }
        </Box>
      </Grid>
    </Grid>
  )
}
