import { Breadcrumbs, Button, Dialog, Grid2 as Grid, IconButton, Link, MenuItem, Select, Stack, TextField, Typography } from "@mui/material"
import { EndpointSpecEditor } from "components/endpoint/EndpointSpecEditor"
import { ProjectContext, ProjectDispatchContext } from "pages/project/ProjectContext";
import { useContext, useEffect, useState } from "react"
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { ModelTypes } from "types/SharedTypes"
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';

export default function EndpointSpecPage() {
  const projectContext = useContext(ProjectContext);
  const project = projectContext?.displaySpec;
  const dispatch = useContext(ProjectDispatchContext);
  const { endpointId } = useParams() as { endpointId: string };
  const navigate = useNavigate();
  const endpoint = project && project.hostSpec.endpoints.asJsReadonlyArrayView().find((e) => e.id === endpointId);

  useEffect(() => {
    if (!project || !endpoint) {
      navigate("../endpoints");
    }
  });

  const [showDebugDialog, setShowDebugDialog] = useState(false);

  if (project && endpoint) {
    return (
      <Grid container p={2} spacing={2}>
        <Grid size={8}>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Breadcrumbs>
              <Link component={RouterLink} underline="hover" color="inherit" to={"../endpoints"}>
                Endpoints
              </Link>
              <Typography sx={{ color: 'text.primary' }}>{endpoint.path}</Typography>
            </Breadcrumbs>
            <IconButton size='small' onClick={() => { setShowDebugDialog(true) }}>
              <RoomPreferencesIcon />
            </IconButton>
          </Stack>

          <EndpointSpecEditor
            projectId={project.id}
            endpointSpec={endpoint}
            applyChange={dispatch}
          />
        </Grid>
        <Grid size={4}>
          <Stack spacing={2}>
            <Typography variant="h6">Try this endpoint with cURL</Typography>
            <code style={{ background: "rgba(0,0,0,0.1)", padding: "4px" }}>
              curl -X POST {endpoint.path}?{endpoint.params.asJsReadonlyArrayView().map((param) => { return param.name + "=<" + param.name + ">" }).join("&")}
            </code>

            <Typography variant="h6">Response format</Typography>
            <code style={{ background: "rgba(0,0,0,0.1)", padding: "4px" }}>
              Coming soon...
            </code>
          </Stack>

        </Grid>
        <Dialog open={showDebugDialog} onClose={() => setShowDebugDialog(false)}>
          <Grid p={4} size={4} overflow={"scroll"}>
            <Button variant='contained' onClick={() => {
              navigator.clipboard.writeText(ModelTypes.endpoint.serializeEndpointSpec(endpoint));
            }}>Copy JSON</Button>
            <pre>{JSON.stringify(JSON.parse(ModelTypes.endpoint.serializeEndpointSpec(endpoint)), null, 2)}</pre>
          </Grid>
        </Dialog>
      </Grid>
    )
  }
}
