import { Button, Card, Dialog, DialogProps, Stack, TextField, Typography } from "@mui/material";
import { ProjectContext, ProjectDispatchContext } from "pages/project/ProjectContext";
import { useContext, useState } from "react";
import { ChangeTypes, ModelTypes } from "types/SharedTypes";
import RegexExpressions from "util/RegexExpressions";

export interface EndpointCreateDialogProps extends DialogProps {
  handleClose: () => void;
}

export default function EndpointCreateDialog(props: EndpointCreateDialogProps) {
  const projectContext = useContext(ProjectContext);
  const dispatch = useContext(ProjectDispatchContext);
  const project = projectContext?.displaySpec;
  const [endpointPath, setEndpointPath] = useState("");
  const existingEndpointPaths = project?.hostSpec.endpoints.asJsReadonlyArrayView().map((m) => m.path) || [];
  const nameIsUnique = !existingEndpointPaths.includes(endpointPath);
  const nameIsValid = nameIsUnique;
  let errorMessage: string | null = null;
  if (!nameIsUnique) {
    errorMessage = "An endpoint with this path already exists.";
  }

  if (!project) {
    return null;
  }

  return (
    <Dialog {...props} onClose={() => { props.handleClose() }} maxWidth="md" fullWidth={true}>
      <Card>
        <Stack p={4} spacing={2}>
          <Typography variant="h5">Create an endpoint</Typography>
          <TextField autoComplete="off" label="Path" variant="outlined" error={errorMessage !== null} helperText={errorMessage} 
          value={endpointPath} onChange={(e) => setEndpointPath(e.target.value)} />
          <Button variant="contained" color="primary" disabled={!nameIsValid} onClick={() => {
            dispatch(new ChangeTypes.Change.HostSpecChange.CreateEndpointSpecChange(project.id, ModelTypes.utils.generateUuid(), endpointPath));
            props.handleClose();
          }}>Create</Button>
        </Stack>
      </Card>
    </Dialog>
  );
}