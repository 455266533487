import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { RoundedCornerStack } from "components/base/RoundedCornerStack";
import { ProjectContext } from "pages/project/ProjectContext";
import { useContext, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import { Link as RouterLink } from "react-router-dom";
import EndpointCreateDialog from "./EndpointCreateDialog";

export default function EndpointListPage() {
  const projectContext = useContext(ProjectContext);
  const [showCreateEndpointDialog, setShowCreateEndpointDialog] = useState(false);
  if (projectContext === null) {
    return;
  }

  const endpointSpecs = projectContext.displaySpec.hostSpec.endpoints.asJsReadonlyArrayView();
  
  const endpoints = endpointSpecs.map((endpoint) => {
    return (
      <Box sx={{
        textDecoration: "none",
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      }} p={2} component={RouterLink} to={`../endpoint/${endpoint.id}`} key={endpoint.id}>
        <Typography variant="h6" color="textPrimary">{endpoint.path}</Typography>
      </Box>
    );
  });

  return (
    <Stack px={2} py={2}>
      <Typography py={2} variant="h5" fontWeight="bold">Endpoints</Typography>
      <RoundedCornerStack pb={1} divider={<Divider />}>
        <Box p={2} display="flex" justifyContent="flex-end" alignItems="center">
          <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => {
            setShowCreateEndpointDialog(true);
          }}>
            Create Endpoint
          </Button>
        </Box>
        <Stack divider={<Divider variant="middle" />}>
          {endpoints}
        </Stack>
      </RoundedCornerStack>
      <EndpointCreateDialog open={showCreateEndpointDialog} handleClose={() => { setShowCreateEndpointDialog(false) }} />
    </Stack>
  );
}
