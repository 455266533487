import { useContext, useEffect } from "react";
import ModelSpecEditor from "components/columns/edit/ModelSpecEditor.tsx";
import { ProjectContext, ProjectDispatchContext } from "../project/ProjectContext";
import { useNavigate, useParams } from "react-router-dom";

export default function ModelSpecPage() {
  const projectContext = useContext(ProjectContext);
  const project = projectContext?.displaySpec;
  const dispatch = useContext(ProjectDispatchContext);
  const { modelId } = useParams() as { modelId: string };
  const navigate = useNavigate();
  const model = project && project.hostSpec.models.asJsReadonlyArrayView().find((m) => m.id === modelId);

  useEffect(() => {
    if (!project || !model) {
      navigate("../models");
    }
  })

  if (model && project) {
    return (
      <ModelSpecEditor projectId={project.id} modelSpec={model} applyChange={(change) => {
        dispatch(change);
      }} />
    );
  }
}