import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from "@mui/material";
import TopicIcon from '@mui/icons-material/Topic';
import PublicIcon from '@mui/icons-material/Public';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SelectContent from "./SelectContent.tsx";
import { useLocation, useNavigate } from "react-router-dom";


const drawerWidth = 240;
const mainListItems = [
  {
    text: 'Overview',
    icon: <DashboardIcon />,
    selectedRegex: /\/overview/g,
    navigationPath: '',
  },
  {
    text: 'Models',
    icon: <TopicIcon />,
    selectedRegex: /\/model/g,
    navigationPath: 'models',
  },
  {
    text: 'Endpoints',
    icon: <PublicIcon />,
    selectedRegex: /\/endpoints/g,
    navigationPath: 'endpoints',
  },
];

export default function AppSideMenu(
  { projectId }: { projectId: string }
) {
  const pathName = useLocation().pathname;
  const navigate = useNavigate();

  return (<Drawer
    variant="permanent"
    sx={{
      width: drawerWidth,
      flexShrink: 0,
      [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
    }}
  >
    <Box sx={{
      display: 'flex',
      p: 1.5,
    }}>
      <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }} spacing={2}>
        <SelectContent />
        <Divider />
        <List dense>
          {mainListItems.map((item, index) => (
            <ListItem key={index} disablePadding sx={{ display: 'block' }}>
              <ListItemButton sx={{
                borderRadius: 4,
              }} selected={new RegExp(item.selectedRegex).test(pathName)}
                onClick={() => {
                  navigate(item.navigationPath.toString());
                }}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Stack>
    </Box>
  </Drawer>)
}

