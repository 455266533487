package com.backit.shared.types.host.columnspecs

import com.backit.shared.types.host.utils.generateUuid
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@JsExport
@SerialName(ColumnSpec.SERIAL_NAME_REFERENCE)
@Serializable
data class ReferenceColumnSpec(
    override val id: String = generateUuid(),
    override val name: String,
    val table: String,
    override val nullable: Boolean = false,
    override val default: Int? = null,
) : ColumnSpec
