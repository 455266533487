import { Grid2 as Grid, Stack, Typography, ToggleButtonGroup, ToggleButton, TextField } from "@mui/material";
import { ChangeTypes, ModelTypes } from "types/SharedTypes.tsx";
import NullablePicker from "./ColumnSpecEditorNullablePicker.tsx";
import React from "react";
import { ColumnSpecCreationEditorProps } from "./ColumnSpecCreationEditor.tsx";

export default function IntegerColumnSpecCreationEditor(props: ColumnSpecCreationEditorProps<ModelTypes.columnspecs.IntColumnSpec>) {
  const {
    projectId,
    modelId,
    columnSpec,
    applyChange,
  } = props;
  const [defaultValue, setDefaultValue] = React.useState(columnSpec.default?.toString());
  return (
    <Grid container spacing={2}>
      <Grid size={6}>
        <NullablePicker {...props} />
      </Grid>
      <Grid size={6}>
        <Stack spacing={1}>
          <Typography variant="caption">Default Value</Typography>
          <ToggleButtonGroup
            color="primary"
            value={columnSpec.default !== null}
            exclusive
            onChange={(
              _event: React.MouseEvent<HTMLElement>,
              value: boolean | null
            ) => {
              if (value !== null) {
                if (value) {
                  setDefaultValue("0");
                }
                applyChange(new ChangeTypes.Change.IntColumnSpecChange.IntColumnSpecDefaultChange(projectId, modelId, columnSpec.id, value ? 0 : null));
              }
            }}
          >
            <ToggleButton value={false}>No Default Value</ToggleButton>
            <ToggleButton value={true}>Set Default Value</ToggleButton>
          </ToggleButtonGroup>
          {columnSpec.default !== null && (
            <TextField
              value={defaultValue}
              type="number"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setDefaultValue(event.target.value);
                var value = parseInt(event.target.value);
                if (isNaN(value)) {
                  value = 0;
                }
                applyChange(new ChangeTypes.Change.IntColumnSpecChange.IntColumnSpecDefaultChange(projectId, modelId, columnSpec.id, value));
              }}
            />
          )}
        </Stack>
      </Grid>
      <Grid size="grow" />
    </Grid>
  );
}