import { alpha, AppBar, Box, Button, IconButton, Stack, styled, Toolbar, Typography } from "@mui/material"
import MemoryIcon from '@mui/icons-material/Memory';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useContext } from "react";
import { ProjectContext, ProjectContextType } from "pages/project/ProjectContext";

const TransparentAppBar = styled(AppBar)(({ theme }) => ({
  background: alpha(theme.palette.background.default, 1),
  boxShadow: 'none',
  color: theme.palette.text.primary,
  backdropFilter: 'blur(12px)',
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'flex',
}));

export default function AppNavbar() {
  const projectContext = useContext(ProjectContext);

  return (
    <TransparentAppBar position="sticky">
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
        >
          <MemoryIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          bend<b>scale</b>
        </Typography>
        <Box sx={{ display: 'block' }}>
          {projectContext && (<ProjectMenuItems projectContext={projectContext} />)}
        </Box>
      </Toolbar>
    </TransparentAppBar >
  )
}

function ProjectMenuItems({
  projectContext
}: {
  projectContext: ProjectContextType
}) {
  const hasUnsavedChanges = projectContext.changeList.length > 0;

  if (hasUnsavedChanges) {
    return (
      <Button endIcon={<OpenInNewIcon />} disabled={!hasUnsavedChanges}>
        Deploy Changes
      </Button>
    )
  } else {
    return (
      <Typography variant="caption">No undeployed changes</Typography>
    )
  }
}