package com.backit.shared.types.host.endpoint

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@JsExport
@Serializable
sealed interface EndpointSelectionSpec

@JsExport
@SerialName("table")
@Serializable
data class TableEndpointSelectionSpec(
    val name: String,
) : EndpointSelectionSpec

@JsExport
@SerialName("join")
@Serializable
data class JoinEndpointSelectionSpec(
    val left: EndpointSelectionSpec,
    val right: EndpointSelectionSpec,
    val leftSelector: JoinColumnSelector,
    val rightSelector: JoinColumnSelector,
    val joinType: JoinType
) : EndpointSelectionSpec {

    @Serializable
    enum class JoinType {
        @SerialName("full")
        FULL,

        @SerialName("inner")
        INNER,

        @SerialName("left")
        LEFT,

        @SerialName("right")
        RIGHT;
    }

    @Serializable
    data class JoinColumnSelector(
        val table: String,
        val column: String,
    )
}
