package com.backit.shared.types.api.response

import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlin.js.JsExport

@Serializable
@JsExport
sealed interface Response

@JsExport
fun Response.serialize(): String {
    return Json.encodeToString(this)
}
