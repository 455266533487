import { MenuItem, Grid2 as Grid, Divider, Select, Button, styled, Stack, FormControl, Box, Typography, Dialog, Breadcrumbs, Link, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ColumnSpecCreationEditor from './ColumnSpecCreationEditor.tsx';
import NameEditor from './NameEditor.tsx';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import { ChangeHandler } from 'types/ChangeHandler.tsx';
import { ChangeTypes, ModelTypes } from "types/SharedTypes.tsx";
import ColumnSpecDisplayRow from 'components/columns/display/ColumnSpecDisplayRow.tsx';
import { Link as RouterLink } from 'react-router-dom';
import { RoundedCornerStack } from 'components/base/RoundedCornerStack.tsx';

interface ModelSpecEditorProps {
  projectId: string,
  modelSpec: ModelTypes.ModelSpec,
  applyChange: ChangeHandler<ChangeTypes.Change.ModelSpecChange>,
}

const ModelSpecEditor: React.FC<ModelSpecEditorProps> = ({ projectId, modelSpec, applyChange }) => {
  const columnSpecs = modelSpec.columns.asJsReadonlyArrayView().map(columnSpec => {
    return (
      <ColumnSpecDisplayRow columnSpec={columnSpec} />
    )
  })
  const [showCreateNewField, setShowCreateNewField] = useState(false);
  const [showDebugDialog, setShowDebugDialog] = useState(false);
  return (
    <Box p={2}>
      <Stack spacing={2}>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Breadcrumbs>
            <Link component={RouterLink} underline="hover" color="inherit" to={"../models"}>
              Models
            </Link>
            <Typography sx={{ color: 'text.primary' }}>{modelSpec.name}</Typography>
          </Breadcrumbs>
          <IconButton size='small' onClick={() => { setShowDebugDialog(true) }}>
            <RoomPreferencesIcon />
          </IconButton>
        </Stack>

        {(columnSpecs.length > 0) && (
          <RoundedCornerStack spacing={1} px={2} py={2} divider={<Divider />}>
            {columnSpecs}
          </RoundedCornerStack>
        )}
        <Divider>
          <Button size="small" startIcon={showCreateNewField ? <RemoveIcon /> : <AddIcon />} onClick={() => {
            setShowCreateNewField(!showCreateNewField);
          }}>New Field</Button>
        </Divider>
        {showCreateNewField && (
          <CreateNewFieldComponent
            projectId={projectId}
            modelSpec={modelSpec}
            applyChange={applyChange}
            onCreateColumn={() => {
              setShowCreateNewField(false);
            }}
          />
        )}
      </Stack>
      <Dialog open={showDebugDialog} onClose={() => setShowDebugDialog(false)}>
        <Grid p={4} size={4} overflow={"scroll"}>
          <Button variant='contained' onClick={() => {
            navigator.clipboard.writeText(ModelTypes.serialize(modelSpec));
          }}>Copy JSON</Button>
          <pre>{JSON.stringify(JSON.parse(ModelTypes.serialize(modelSpec)), null, 2)}</pre>
        </Grid>
      </Dialog>
    </Box >
  );
}

export default ModelSpecEditor;

function CreateNewFieldComponent({
  projectId,
  modelSpec,
  applyChange,
  onCreateColumn,
}: {
  projectId: string,
  modelSpec: ModelTypes.ModelSpec,
  applyChange: ChangeHandler<ChangeTypes.Change.ModelSpecChange>,
  onCreateColumn: () => void,
}) {
  const [newColumnName, setNewColumnName] = useState("");
  const [newColumnType, setNewColumnType] = useState<string | null>(null);
  const [newColumnSpec, setNewColumnSpec] = useState<ModelTypes.columnspecs.ColumnSpec | null>(null);
  const newSpecValid = newColumnSpec !== null && newColumnSpec.name.length > 0;

  const columnTypeOptions = ModelTypes.columnspecs.ColumnType.values().map((type: ModelTypes.columnspecs.ColumnType) => {
    return (
      <MenuItem value={type.name}>{type.serialName}</MenuItem>
    )
  })

  const onCreateNewColumn = (name: string, type: string) => {
    if (newColumnSpec === null) {
      return;
    }
    applyChange(new ChangeTypes.Change.ModelSpecChange.ModelSpecAddColumnChange(projectId, modelSpec.id, newColumnSpec));
    setNewColumnSpec(null);
    setNewColumnName("");
    setNewColumnType(null);
    onCreateColumn();
  }

  useEffect(() => {
    if (newColumnSpec !== null) {
      setNewColumnName(newColumnSpec.name);
    }
  }, [newColumnSpec]);

  return (<RoundedCornerStack spacing={2} px={2} py={4}>
    <Grid container spacing={2}>
      <Grid size={4}>
        <Stack spacing={1}>
          <Typography variant="h5">Create New Field</Typography>
          <Typography variant="caption">Field Type</Typography>
          <FormControl>
            <Select
              displayEmpty
              value={newColumnType ?? ""} onChange={(e) => {
                var value: string | null = e.target.value;
                if (!value || value === "") {
                  value = null;
                }
                console.log(value);
                setNewColumnType(value!);
                setNewColumnSpec(value && ModelTypes.columnspecs.ColumnSpec.createDefault(newColumnName, value));
              }}>
              <MenuItem value="">Select a field type</MenuItem>
              {columnTypeOptions}
            </Select>
          </FormControl>
          <Typography variant="caption">Field Name</Typography>
          <Stack>
            <NameEditor name={newColumnName} onNameChanged={(newName) => {
              setNewColumnName(newName);
              if (newColumnSpec === null) {
                return;
              }
              const newSpec = ChangeTypes.applyColumnSpecChange(
                newColumnSpec,
                new ChangeTypes.Change.ColumnSpecChange.ColumnSpecNameChange(projectId, modelSpec.id, newColumnSpec.id, newName)
              )
              setNewColumnSpec(newSpec);
            }} />
          </Stack>
        </Stack>
      </Grid>
      <Grid size={8}>
        {newColumnSpec !== null && (
          <>
            <Typography pb={1} variant="h6">Field Properties</Typography>

            <ColumnSpecCreationEditor projectId={projectId} modelId={modelSpec.id} columnSpec={newColumnSpec} applyChange={(change) => {
              var columnSpec = newColumnSpec;
              if (Array.isArray(change)) {
                change.forEach((c) => {
                  columnSpec = ChangeTypes.applyColumnSpecChange(columnSpec, c)
                });
              } else {
                columnSpec = ChangeTypes.applyColumnSpecChange(columnSpec, change);
              }
              setNewColumnSpec(columnSpec);
            }} />
          </>
        )}
      </Grid>
    </Grid>
    {newColumnSpec !== null && newColumnType !== null && (
      <>
        <Divider>
          <Typography variant="overline">Preview</Typography>
        </Divider>
        <ColumnSpecDisplayRow columnSpec={newColumnSpec} />
        <Divider />
        <Stack direction="row" justifyContent="flex-end">
          <Button variant="outlined" disabled={!newSpecValid} onClick={() => {
            onCreateNewColumn(newColumnName, newColumnType);
          }}>
            Create new field
          </Button>
        </Stack>
      </>
    )}
  </RoundedCornerStack>);
}
