package com.backit.shared.types.host.changes

import com.backit.shared.types.host.HostSpec
import com.backit.shared.types.host.ModelSpec
import com.backit.shared.types.host.ProjectSpec
import com.backit.shared.types.host.changes.Change.*
import com.backit.shared.types.host.changes.Change.ColumnSpecChange.ColumnSpecNameChange
import com.backit.shared.types.host.changes.Change.ColumnSpecChange.ColumnSpecNullableChange
import com.backit.shared.types.host.changes.Change.ModelSpecChange.ModelSpecAddColumnChange
import com.backit.shared.types.host.changes.handlers.*
import com.backit.shared.types.host.columnspecs.*
import com.backit.shared.types.host.endpoint.EndpointParamSpec
import com.backit.shared.types.host.endpoint.EndpointSelectionSpec
import com.backit.shared.types.host.endpoint.EndpointSpec
import com.backit.shared.types.host.endpoint.TableEndpointSelectionSpec
import kotlin.js.JsExport

@JsExport
fun ProjectSpec.applyProjectSpecChange(change: ProjectSpecChange): ProjectSpec {
    if (id != change.projectId) {
        return this
    }
    return when (change) {
        is ProjectSpecChange.ProjectSpecNameChange -> copy(name = change.newName)
        is HostSpecChange -> copy(hostSpec = hostSpec.applyHostSpecChange(change))
    }
}

@JsExport
fun HostSpec.applyHostSpecChange(change: HostSpecChange): HostSpec {
    return when (change) {
        is ModelSpecChange -> {
            copy(models = models.map { model ->
                model.applyModelSpecChange(change)
            })
        }

        is EndpointSpecChange -> {
            copy(endpoints = endpoints.map { endpoint ->
                endpoint.applyEndpointSpecChange(change)
            })
        }

        is HostSpecChange.CreateModelSpecChange -> {
            copy(models = models + ModelSpec(id = change.modelId, name = change.modelName, columns = emptyList()))
        }

        is HostSpecChange.CreateEndpointSpecChange -> {
            copy(
                endpoints = endpoints + EndpointSpec(
                    id = change.endpointId,
                    path = change.path,
                    params = emptyList(),
                    selection = TableEndpointSelectionSpec(models.firstOrNull()?.name ?: ""),
                    filter = null,
                )
            )
        }
    }
}

@JsExport
fun EndpointSpec.applyEndpointSpecChange(
    change: EndpointSpecChange,
): EndpointSpec {
    if (id != change.endpointId) {
        return this
    }
    return when (change) {
        is EndpointSpecChange.EndpointPathChange -> copy(path = change.newPath)
        is EndpointSpecChange.EndpointParamSpecChange -> copy(params = params.map { param ->
            param.applyEndpointParamSpecChange(change)
        })

        is EndpointSpecChange.EndpointSelectionChange -> copy(
            selection = change.newSelection
        )

        is EndpointSpecChange.EndpointFilterChange -> copy(
            filter = change.newFilter
        )

        is EndpointSpecChange.EndpointAddParamChange -> copy(
            params = params + change.paramSpec
        )
    }
}

fun EndpointParamSpec.applyEndpointParamSpecChange(
    change: EndpointSpecChange.EndpointParamSpecChange,
): EndpointParamSpec {
    if (id != change.paramId) {
        return this
    }
    return when (change) {
        is EndpointSpecChange.EndpointParamSpecChange.EndpointParamNameChange -> copy(name = change.newName)
        is EndpointSpecChange.EndpointParamSpecChange.EndpointParamTypeChange -> copy(type = change.newType)
    }
}

@JsExport
fun ModelSpec.applyModelSpecChange(
    change: ModelSpecChange,
): ModelSpec {
    if (id != change.modelId) {
        return this
    }
    return when (change) {
        is ColumnSpecChange -> {
            copy(columns = columns.map { column ->
                column.applyColumnSpecChange(change)
            })
        }

        is ModelSpecAddColumnChange -> applyModelSpecAddColumnChange(change)
    }
}

@JsExport
fun ColumnSpec.applyColumnSpecChange(
    change: ColumnSpecChange,
): ColumnSpec {
    if (id != change.columnId) {
        return this
    }
    return when (change) {
        is ColumnSpecNameChange -> applyColumnSpecNameChange(change)
        is ColumnSpecNullableChange -> applyColumnSpecNullableChange(change)
        is BooleanColumnSpecChange -> applyBooleanColumnSpecChange(change)
        is FloatColumnSpecChange -> applyFloatColumnSpecChange(change)
        is IntColumnSpecChange -> applyIntColumnSpecChange(change)
        is LongColumnSpecChange -> applyLongColumnSpecChange(change)
        is ReferenceColumnSpecChange -> applyReferenceColumnSpecChange(change)
        is TextColumnSpecChange -> applyTextColumnSpecChange(change)
        is VarCharColumnSpecChange -> applyVarCharColumnSpecChange(change)
    }
}

fun ColumnSpec.applyBooleanColumnSpecChange(
    change: BooleanColumnSpecChange
): BooleanColumnSpec {
    if (this !is BooleanColumnSpec) {
        error("BooleanColumnSpecChange applied to non-boolean column spec")
    }
    return when (change) {
        is BooleanColumnSpecChange.BooleanColumnSpecDefaultChange -> applyBooleanColumnSpecDefaultChange(change)
    }
}

fun ColumnSpec.applyFloatColumnSpecChange(
    change: FloatColumnSpecChange
): ColumnSpec {
    if (this !is FloatColumnSpec) {
        error("FloatColumnSpecChange applied to non-float column spec")
    }
    return when (change) {
        is FloatColumnSpecChange.FloatColumnSpecDefaultChange -> applyFloatColumnSpecDefaultChange(change)
    }
}

fun ColumnSpec.applyIntColumnSpecChange(
    change: IntColumnSpecChange
): ColumnSpec {
    if (this !is IntColumnSpec) {
        error("IntColumnSpecChange applied to non-int column spec")
    }
    return when (change) {
        is IntColumnSpecChange.IntColumnSpecDefaultChange -> applyIntColumnSpecDefaultChange(change)
    }
}

fun ColumnSpec.applyLongColumnSpecChange(
    change: LongColumnSpecChange
): ColumnSpec {
    if (this !is LongColumnSpec) {
        error("LongColumnSpecChange applied to non-long column spec")
    }
    return when (change) {
        is LongColumnSpecChange.LongColumnSpecDefaultChange -> applyLongColumnSpecDefaultChange(change)
    }
}

fun ColumnSpec.applyReferenceColumnSpecChange(
    change: ReferenceColumnSpecChange
): ColumnSpec {
    if (this !is ReferenceColumnSpec) {
        error("ReferenceColumnSpecChange applied to non-reference column spec")
    }
    return when (change) {
        is ReferenceColumnSpecChange.ReferenceColumnSpecDefaultChange -> applyReferenceColumnSpecDefaultChange(change)
        is ReferenceColumnSpecChange.ReferenceColumnSpecTableChange -> applyReferenceColumnSpecTableChange(change)
    }
}

fun ColumnSpec.applyTextColumnSpecChange(
    change: TextColumnSpecChange
): ColumnSpec {
    if (this !is TextColumnSpec) {
        error("TextColumnSpecChange applied to non-text column spec")
    }
    return when (change) {
        is TextColumnSpecChange.TextColumnSpecDefaultChange -> applyTextColumnSpecDefaultChange(change)
    }
}

fun ColumnSpec.applyVarCharColumnSpecChange(
    change: VarCharColumnSpecChange
): ColumnSpec {
    if (this !is VarcharColumnSpec) {
        error("VarCharColumnSpecChange applied to non-varchar column spec")
    }
    return when (change) {
        is VarCharColumnSpecChange.VarCharColumnSpecDefaultChange -> applyVarCharColumnSpecDefaultChange(change)
    }
}
