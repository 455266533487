package com.backit.shared.types.api.response

import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@Serializable
@JsExport
sealed interface ControlServerResponse : Response

@Serializable
data class HostGenerateResponse(
    val orgId: String,
    val queueId: Int,
    val message: String,
)
