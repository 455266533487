import { Box } from '@mui/material';
import React from 'react';
import BooleanColumnSpecRow from './BooleanColumnSpecRow.tsx';
import FloatColumnSpecRow from './FloatColumnSpecRow.tsx';
import IntegerColumnSpecRow from './IntegerColumnSpecRow.tsx';
import ReferenceColumnSpecRow from './ReferenceColumnSpecRow.tsx';
import LongColumnSpecRow from './LongColumnSpecRow.tsx';
import VarcharColumnSpecRow from './VarcharColumnSpecRow.tsx';
import TextColumnSpecRow from './TextColumnSpecRow.tsx';
import { ModelTypes } from 'types/SharedTypes.tsx';

interface ColumnSpecRowProps {
  columnSpec: ModelTypes.columnspecs.ColumnSpec,
}

const ColumnSpecDisplayRow: React.FC<ColumnSpecRowProps> = ({ columnSpec }) => {
  if (columnSpec instanceof ModelTypes.columnspecs.BooleanColumnSpec) {
    return (
      <BooleanColumnSpecRow columnSpec={columnSpec} />
    )
  } else if (columnSpec instanceof ModelTypes.columnspecs.FloatColumnSpec) {
    return (
      <FloatColumnSpecRow columnSpec={columnSpec} />
    )
  } else if (columnSpec instanceof ModelTypes.columnspecs.IntColumnSpec) {
    return (
      <IntegerColumnSpecRow columnSpec={columnSpec} />
    )
  } else if (columnSpec instanceof ModelTypes.columnspecs._LongColumnSpec) {
    return (
      <LongColumnSpecRow columnSpec={columnSpec} />
    )
  } else if (columnSpec instanceof ModelTypes.columnspecs.ReferenceColumnSpec) {
    return (
      <ReferenceColumnSpecRow columnSpec={columnSpec} />
    )
  } else if (columnSpec instanceof ModelTypes.columnspecs.VarcharColumnSpec) {
    return (
      <VarcharColumnSpecRow columnSpec={columnSpec} />
    )
  } else if (columnSpec instanceof ModelTypes.columnspecs.TextColumnSpec) {
    return (
      <TextColumnSpecRow columnSpec={columnSpec} />
    )
  } else {
    return (
      <div>
        <p style={{ backgroundColor: "red" }}>Not Implemented</p>
        <p>{ModelTypes.columnspecs.serialize(columnSpec)}</p>
      </div>
    )
  }
}

export default ColumnSpecDisplayRow;
