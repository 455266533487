package com.backit.shared.types.deployment

import kotlinx.serialization.Serializable

@Serializable
sealed interface DeployStatus {
    @Serializable
    data object Initialized : DeployStatus
    @Serializable
    data object Deploying : DeployStatus
    @Serializable
    data object ImageBuilt : DeployStatus
    @Serializable
    data object Provisioning : DeployStatus
    @Serializable
    data class ProvisionComplete(val baseUrl: String) : DeployStatus

    @Serializable
    sealed interface Error : DeployStatus {
        @Serializable
        data object ExpectedProjectSpecDoesNotMatch : Error
        @Serializable
        data class InvalidProjectId(val projectId: String) : Error
        @Serializable
        data object NoProvisionedHostFound : Error
    }
}
