import { Box, Button, ButtonBase, Card, Dialog, Divider, Link, Stack, Typography } from "@mui/material";
import { RoundedCornerStack } from "components/base/RoundedCornerStack";
import { ProjectContext, ProjectDispatchContext } from "pages/project/ProjectContext";
import { useContext, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import ModelCreateDialog from "./ModelCreateDialog";

export default function ModelListPage() {
  const projectContext = useContext(ProjectContext);
  const dispatch = useContext(ProjectDispatchContext);
  const [showCreateModelDialog, setShowCreateModelDialog] = useState(false);

  if (!projectContext) {
    return null;
  }

  const project = projectContext.displaySpec;

  const modelComponents = project.hostSpec.models.asJsReadonlyArrayView().map((model) => {
    return (
      <Box sx={{
        textDecoration: "none",
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      }} p={2} component={RouterLink} to={`../model/${model.id}`} key={model.id}>
        <Typography variant="h6" color="textPrimary">{model.name}</Typography>
      </Box>
    )
  });

  const hasPendingChanges = projectContext.changeList.length > 0;

  return (
    <>
      <Stack px={2} py={2}>
        <Typography py={2} variant="h5" fontWeight="bold">Models</Typography>
        <RoundedCornerStack pb={1} divider={<Divider />}>
          <Box p={2} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="body2" fontStyle={hasPendingChanges ? "italic" : "normal"}>
              {hasPendingChanges ? "Undeployed changes" : "No undeployed changes"}
            </Typography>
            <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => {
              setShowCreateModelDialog(true);
            }}>
              Create Model
            </Button>
          </Box>
          <Stack divider={<Divider variant="middle" />}>
            {modelComponents}
          </Stack>
        </RoundedCornerStack>
      </Stack>
      <ModelCreateDialog open={showCreateModelDialog} handleClose={() => { setShowCreateModelDialog(false) }} />
    </>
  );
}