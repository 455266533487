import { BackitApiClientContext } from 'pages/AppRootPage';
import { createContext, PropsWithChildren, useContext, useReducer } from 'react';
import { ChangeTypes, ModelTypes } from 'types/SharedTypes';

export type ProjectContextType = {
  originalSpec: ModelTypes.ProjectSpec;
  displaySpec: ModelTypes.ProjectSpec;
  changeList: ChangeTypes.Change.ProjectSpecChange[];
}
export const ProjectContext = createContext<ProjectContextType | null>(null);
export const ProjectDispatchContext = createContext<React.Dispatch<ChangeTypes.Change.ProjectSpecChange | ChangeTypes.Change.ProjectSpecChange[]>>(() => {
  console.error("ProjectDispatchContext used without a provider");
});

export interface ProjectProviderProps extends PropsWithChildren {
  initialProjectSpec: ModelTypes.ProjectSpec;
}

export function ProjectProvider({ children, initialProjectSpec }: ProjectProviderProps) {
  const [displaySpec, dispatch] = useReducer(
    projectReducer,
    {
      originalSpec: initialProjectSpec,
      displaySpec: initialProjectSpec,
      changeList: []
    }
  );
  
  return (
    <ProjectContext.Provider value={displaySpec}>
      <ProjectDispatchContext.Provider value={dispatch}>
        {children}
      </ProjectDispatchContext.Provider>
    </ProjectContext.Provider>
  );
}

function projectReducer(projectContext: ProjectContextType, action: ChangeTypes.Change.ProjectSpecChange | ChangeTypes.Change.ProjectSpecChange[]): ProjectContextType {
  const newChangeList = Array.isArray(action) ? action : [action];
  var newDisplaySpec = projectContext.displaySpec;
  newChangeList.forEach((change) => {
    newDisplaySpec = ChangeTypes.applyProjectSpecChange(newDisplaySpec, change);
  });
  return {
    originalSpec: projectContext.originalSpec,
    displaySpec: newDisplaySpec,
    changeList: projectContext.changeList.concat(newChangeList)
  }
}
