package com.backit.shared.types.provisioning

import kotlinx.serialization.Serializable

@Serializable
data class DatabaseConnectionConfig(
    val name: String,
    val username: String,
    val password: String,
    val url: String,
)