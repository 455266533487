package com.backit.shared.types.host.changes

import com.backit.shared.types.host.columnspecs.ColumnSpec
import com.backit.shared.types.host.endpoint.EndpointFilterSpec
import com.backit.shared.types.host.endpoint.EndpointParamSpec
import com.backit.shared.types.host.endpoint.EndpointParamType
import com.backit.shared.types.host.endpoint.EndpointSelectionSpec
import kotlinx.serialization.Serializable
import kotlin.js.JsExport
import kotlin.js.JsName

@Serializable
@JsExport
sealed class Change {
    @Serializable
    sealed class ProjectSpecChange : Change() {
        abstract val projectId: String

        @Serializable
        data class ProjectSpecNameChange(
            override val projectId: String,
            val newName: String,
        ) : ProjectSpecChange()
    }

    @Serializable
    sealed class HostSpecChange : ProjectSpecChange() {
        @Serializable
        data class CreateModelSpecChange(
            override val projectId: String,
            val modelName: String,
            val modelId: String,
        ) : HostSpecChange()

        @Serializable
        data class CreateEndpointSpecChange(
            override val projectId: String,
            val endpointId: String,
            val path: String,
        ) : HostSpecChange()
    }

    @Serializable
    sealed class EndpointSpecChange : HostSpecChange() {
        abstract val endpointId: String

        @Serializable
        data class EndpointPathChange(
            override val projectId: String,
            override val endpointId: String,
            val newPath: String,
        ) : EndpointSpecChange()

        @Serializable
        data class EndpointSelectionChange(
            override val projectId: String,
            override val endpointId: String,
            val newSelection: EndpointSelectionSpec,
        ) : EndpointSpecChange()

        @Serializable
        data class EndpointFilterChange(
            override val projectId: String,
            override val endpointId: String,
            val newFilter: EndpointFilterSpec?,
        ) : EndpointSpecChange()

        @Serializable
        data class EndpointAddParamChange(
            override val projectId: String,
            override val endpointId: String,
            val paramSpec: EndpointParamSpec,
        ) : EndpointSpecChange()

        sealed class EndpointParamSpecChange : EndpointSpecChange() {
            abstract val paramId: String

            @Serializable
            data class EndpointParamTypeChange(
                override val projectId: String,
                override val endpointId: String,
                override val paramId: String,
                val newType: EndpointParamType,
            ) : EndpointParamSpecChange()

            @Serializable
            data class EndpointParamNameChange(
                override val projectId: String,
                override val endpointId: String,
                override val paramId: String,
                val newName: String,
            ) : EndpointParamSpecChange()
        }



    }

    @Serializable
    sealed class ModelSpecChange : HostSpecChange() {
        abstract val modelId: String

        @Serializable
        data class ModelSpecAddColumnChange(
            override val projectId: String,
            override val modelId: String,
            val columnSpec: ColumnSpec,
        ) : ModelSpecChange()
    }

    @Serializable
    sealed class ColumnSpecChange : ModelSpecChange() {
        abstract val columnId: String

        @Serializable
        data class ColumnSpecNameChange(
            override val projectId: String,
            override val modelId: String,
            override val columnId: String,
            val newName: String,
        ) : ColumnSpecChange()

        @Serializable
        data class ColumnSpecNullableChange(
            override val projectId: String,
            override val modelId: String,
            override val columnId: String,
            val nullable: Boolean,
        ) : ColumnSpecChange()
    }

    @Serializable
    sealed class BooleanColumnSpecChange : ColumnSpecChange() {
        @Serializable
        data class BooleanColumnSpecDefaultChange(
            override val projectId: String,
            override val modelId: String,
            override val columnId: String,
            val default: Boolean?,
        ) : BooleanColumnSpecChange()
    }

    @Serializable
    sealed class FloatColumnSpecChange : ColumnSpecChange() {
        @Serializable
        data class FloatColumnSpecDefaultChange(
            override val projectId: String,
            override val modelId: String,
            override val columnId: String,
            val default: Float?,
        ) : FloatColumnSpecChange()
    }

    @Serializable
    sealed class IntColumnSpecChange : ColumnSpecChange() {
        @Serializable
        data class IntColumnSpecDefaultChange(
            override val projectId: String,
            override val modelId: String,
            override val columnId: String,
            val default: Int?,
        ) : IntColumnSpecChange()
    }

    @Serializable
    sealed class LongColumnSpecChange : ColumnSpecChange() {
        @Serializable
        @JsName("_LongColumnSpecDefaultChange")
        data class LongColumnSpecDefaultChange(
            override val projectId: String,
            override val modelId: String,
            override val columnId: String,
            val default: Long?,
        ) : LongColumnSpecChange()

        fun LongColumnSpecDefaultChange(
            projectId: String,
            modelId: String,
            columnName: String,
            default: Int?,
        ): LongColumnSpecDefaultChange {
            return LongColumnSpecDefaultChange(
                projectId = projectId,
                modelId = modelId,
                columnId = columnName,
                default = default?.toLong()
            )
        }
    }

    @Serializable
    sealed class ReferenceColumnSpecChange : ColumnSpecChange() {
        @Serializable
        data class ReferenceColumnSpecDefaultChange(
            override val projectId: String,
            override val modelId: String,
            override val columnId: String,
            val default: Int?,
        ) : ReferenceColumnSpecChange()

        @Serializable
        data class ReferenceColumnSpecTableChange(
            override val projectId: String,
            override val modelId: String,
            override val columnId: String,
            val table: String,
        ) : ReferenceColumnSpecChange()
    }

    @Serializable
    sealed class TextColumnSpecChange : ColumnSpecChange() {
        @Serializable
        data class TextColumnSpecDefaultChange(
            override val projectId: String,
            override val modelId: String,
            override val columnId: String,
            val default: String?,
        ) : TextColumnSpecChange()

    }

    @Serializable
    sealed class VarCharColumnSpecChange : ColumnSpecChange() {
        @Serializable
        data class VarCharColumnSpecDefaultChange(
            override val projectId: String,
            override val modelId: String,
            override val columnId: String,
            val default: String?,
        ) : VarCharColumnSpecChange()
    }
}


