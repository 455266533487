import { Box, Button, Dialog, Grid2 as Grid, IconButton, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ProjectContext } from "./project/ProjectContext";
import { ApiTypes, ModelTypes } from "types/SharedTypes";
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import { RoundedCornerStack } from "components/base/RoundedCornerStack";
import { BackitApiClientContext } from "./AppRootPage";

export default function ProjectOverviewPage() {
  const [showDebugDialog, setShowDebugDialog] = useState(false);
  const [deployStatus, setDeployStatus] = useState<string | null>(null);
  const projectContext = useContext(ProjectContext);
  const backitApiClient = useContext(BackitApiClientContext);

  
  useEffect(() => {
    const checkDeployStatus = () => {
      backitApiClient?.getDeployStatus("kz-test-12345", project.id).then((response) => {
        setDeployStatus(response);
        setTimeout(checkDeployStatus, 5000);
      });
    }
    checkDeployStatus();
  }, []);
  
  if (!projectContext) {
    return null;
  }

  const originalProject = projectContext.originalSpec;
  const project = projectContext.displaySpec;
  const changeList = projectContext.changeList;
  const hasPendingChanges = true;

  return (
    <>
      <Grid container py={4}>
        <Grid offset={4} size={4}>
          <RoundedCornerStack p={2} spacing={1}>
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Typography variant="h5">{project.name}</Typography>
              <IconButton size='small' onClick={() => { setShowDebugDialog(true) }}>
                <RoomPreferencesIcon />
              </IconButton>
            </Stack>
            <Stack spacing={1} py={2}>
              <Typography variant="body1">{project.hostSpec.models.asJsReadonlyArrayView().length} Models</Typography>
              <Typography variant="body1">{project.hostSpec.endpoints.asJsReadonlyArrayView().length} Endpoints</Typography>
              <Typography variant="body1">{changeList.length} Changes</Typography>
              {deployStatus && <Typography variant="caption" sx={{
                width: "100%",
                textAlign: "right",
              }}>{deployStatus}</Typography>}
            </Stack>
            <Button variant='contained' disabled={!hasPendingChanges} onClick={() => {
              backitApiClient?.postDeploy(new ApiTypes.request.DeployRequest("kz-test-12345", project.id, originalProject, ModelTypes.toList(changeList), project))
            }}>{hasPendingChanges ? "Deploy" : "No undeployed changes"}</Button>
          </RoundedCornerStack>
        </Grid>
      </Grid>
      <Dialog maxWidth='xl' fullWidth={true} open={showDebugDialog} onClose={() => setShowDebugDialog(false)}>
        <Grid container spacing={2} overflow="auto" p={2}>
          <Grid size={4} overflow="auto">
            <Typography variant="h6">Original Project</Typography>
            <pre>{JSON.stringify(JSON.parse(ModelTypes.serializeProjectSpec(originalProject)), null, 2)}</pre>
          </Grid>
          <Grid size={4} overflow="auto">
            <Stack direction={"row"} spacing={2} justifyContent="space-between">
              <Typography variant="h6">Current Project</Typography>
              <Button variant='contained' onClick={() => {
                navigator.clipboard.writeText(ModelTypes.serializeProjectSpec(project));
              }}>Copy JSON</Button>
            </Stack>
            <pre>{JSON.stringify(JSON.parse(ModelTypes.serializeProjectSpec(project)), null, 2)}</pre>
          </Grid>
          <Grid size={4} overflow="auto">
            <Typography variant="h6">Change List</Typography>
            <pre>{JSON.stringify(JSON.parse(ModelTypes.serializeChangeList(ModelTypes.toList(changeList))), null, 2)}</pre>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}