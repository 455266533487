package com.backit.shared.types.api.request

import com.backit.shared.types.deployment.DeployStatus
import com.backit.shared.types.host.ProjectSpec
import com.backit.shared.types.host.changes.Change
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@Serializable
@JsExport
sealed interface ConfigServerRequest : Request

@Serializable
@JsExport
data class DeployRequest(
    val orgId: String,
    val projectId: String,
    @Deprecated("Temporary. We should from the database to ensure that the original project spec is correct")
    val originalProjectSpec: ProjectSpec,
    val changeList: List<Change.ProjectSpecChange>,
    val expectedProjectSpec: ProjectSpec,
) : ConfigServerRequest

@Serializable
data class SetDeployStatusRequest(
    val orgId: String,
    val projectId: String,
    val status: DeployStatus,
) : ConfigServerRequest

@JsExport
@Serializable
data class GetProjectRequest(
    val orgId: String,
    val projectId: String,
) : ConfigServerRequest

@JsExport
@Serializable
data class GetDeployStatusRequest(
    val orgId: String,
    val projectId: String,
) : ConfigServerRequest
