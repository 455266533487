import { Box, Button, Grid2 as Grid, Stack, Typography } from "@mui/material";
import { RoundedCornerBox } from "components/base/RoundedCornerBox";
import { RoundedCornerStack } from "components/base/RoundedCornerStack";
import BackitApiClient from "http/BackitApiClient";
import { createContext, useEffect, useMemo, useState } from "react";
import { Link, Outlet, useMatch, useNavigate } from "react-router-dom";

export const BackitApiClientContext = createContext<BackitApiClient | null>(null);

export default function AppRootPage({
  token,
  clearToken
}: {
  token: string;
  clearToken: () => void;
}) {
  const isHome = useMatch("/app");
  const [response, setResponse] = useState<string | null>(null);
  const navigate = useNavigate();

  let content;
  if (isHome) {
    content = (
      <Grid container
        sx={{
          paddingTop: "24px",
          alignItems: "center",
        }}>
        <Grid size={4} offset={4}>
          <Stack spacing={2}>
            <Typography variant="caption">Server status: {response}</Typography>
            <Typography variant="h4" sx={{
              paddingTop: "36px",
            }}>Projects</Typography>
            <RoundedCornerBox
              sx={{
                textDecoration: "none",
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
                cursor: "pointer",
              }}
              onClick={() => { navigate("p/860a0345-9c3f-45e5-89b6-02fedff35aa7") }}
            >
              <Typography variant="h6" sx={{
                width: "100%",
                textAlign: "center",
                padding: "12px",
              }}>Testing Project</Typography>
            </RoundedCornerBox>

            <RoundedCornerBox
              sx={{
                textDecoration: "none",
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
                cursor: "pointer",
              }}
              onClick={() => { navigate("p/bea295d3-3333-4a54-b4e6-3b75938c431b") }}
            >
              <Typography variant="h6" sx={{
                width: "100%",
                textAlign: "center",
                padding: "12px",
              }}>Demo Project</Typography>

            </RoundedCornerBox>
            <Button variant="outlined">+ Create project</Button>
          </Stack>
        </Grid>
      </Grid>
    )
  } else {
    content = (<Outlet />);
  }

  const client = useMemo(() => { return new BackitApiClient(getServerUrl(), token, clearToken) }, []);
  useEffect(() => {
    client.getTestRoute().then(setResponse);
  });

  return (
    <BackitApiClientContext.Provider value={client}>
      {content}
    </BackitApiClientContext.Provider>
  )
}

function getServerUrl(): string {
  const env = process.env.NODE_ENV;
  let url = "https://bendscale.com";
  if (env === "development") {
    console.log("Development mode detected, using localhost.");
    url = "http://localhost:8080";
  }
  return url;
}