import { Grid2 as Grid, Stack, Typography, ToggleButtonGroup, ToggleButton } from "@mui/material";
import React from "react";
import NullablePicker from "./ColumnSpecEditorNullablePicker.tsx";
import { ChangeTypes, ModelTypes } from "types/SharedTypes.tsx";
import { ChangeHandler } from 'types/ChangeHandler.tsx'
import { ColumnSpecCreationEditorProps } from "./ColumnSpecCreationEditor.tsx";

export default function BooleanColumnSpecCreationEditor(props: ColumnSpecCreationEditorProps<ModelTypes.columnspecs.BooleanColumnSpec>) {
  const {
    projectId,
    modelId,
    columnSpec,
    applyChange,
  } = props;
  return (
    <Grid container spacing={2}>
      <Grid size={6}>
        <NullablePicker {...props} />
      </Grid>
      <Grid size={6}>
        <Stack spacing={1}>
          <Typography variant="caption">Default Value</Typography>
          <ToggleButtonGroup
            color="primary"
            value={columnSpec.default ?? "none"}
            exclusive
            onChange={(
              _event: React.MouseEvent<HTMLElement>,
              value: boolean | string | null
            ) => {
              if (value !== null) {
                applyChange(new ChangeTypes.Change.BooleanColumnSpecChange.BooleanColumnSpecDefaultChange(projectId, modelId, columnSpec.id, value === "none" ? null : value as boolean));
              }
            }}
          >
            <ToggleButton value={false}>False</ToggleButton>
            <ToggleButton value={"none"}>No Default Value</ToggleButton>
            <ToggleButton value={true}>True</ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Grid>
      <Grid size="grow" />
    </Grid>
  );
}