import React from "react";
import { ChangeHandler } from "types/ChangeHandler.tsx";
import { ChangeTypes, ModelTypes } from "types/SharedTypes.tsx";
import FloatColumnSpecCreationEditor from "./FloatColumnSpecCreationEditor.tsx";
import BooleanColumnSpecCreationEditor from "./BooleanColumnSpecCreationEditor.tsx";
import IntegerColumnSpecCreationEditor from "./IntegerColumnSpecCreationEditor.tsx";
import ReferenceColumnSpecCreationEditor from "./ReferenceColumnSpecCreationEditor.tsx";


export interface ColumnSpecCreationEditorProps<T extends ModelTypes.columnspecs.ColumnSpec> {
  projectId: string;
  modelId: string;
  columnSpec: T;
  applyChange: ChangeHandler<ChangeTypes.Change.ColumnSpecChange>;
}

const ColumnSpecCreationEditor: React.FC<ColumnSpecCreationEditorProps<ModelTypes.columnspecs.ColumnSpec>> = (props) => {
  const {columnSpec, modelId, applyChange} = props;

  if (columnSpec instanceof ModelTypes.columnspecs.BooleanColumnSpec) {
    return (
      <BooleanColumnSpecCreationEditor {...props} columnSpec={columnSpec} />
    );
  } else if (columnSpec instanceof ModelTypes.columnspecs.FloatColumnSpec) {
    return (
      <FloatColumnSpecCreationEditor  {...props} columnSpec={columnSpec} />
    );
  } else if (columnSpec instanceof ModelTypes.columnspecs.IntColumnSpec) {
    return (
      <IntegerColumnSpecCreationEditor {...props} columnSpec={columnSpec} />
    );
  } else if (columnSpec instanceof ModelTypes.columnspecs.ReferenceColumnSpec) {
    return (
      <ReferenceColumnSpecCreationEditor {...props} columnSpec={columnSpec} />
    )
  } else {
    return (
      <div>
        <p style={{ backgroundColor: "red" }}>Not Implemented</p>
        <p>{ModelTypes.columnspecs.serialize(columnSpec)}</p>
      </div>
    );
  }
};



export default ColumnSpecCreationEditor;