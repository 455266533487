import { Typography, Box, Chip } from "@mui/material";
import React from "react";
import { ModelTypes } from "types/SharedTypes.tsx";
import HideSourceIcon from '@mui/icons-material/HideSource';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';

export default function NullableDisplay({
  columnSpec,
}: {
  columnSpec: ModelTypes.columnspecs.ColumnSpec,
}) {
  return <>
    <Typography variant="caption">Nullability</Typography>
    <Box py={1}>
      {columnSpec.nullable ?
        (<Chip size="small" color="info" label="Nullable" icon={<HideSourceIcon />} />) :
        (<Chip size="small" color="info" label="Non Null" variant="outlined" icon={<CircleOutlinedIcon />} />)}
    </Box>
  </>;
}

