import { Button, Card, Dialog, DialogProps, Stack, TextField, Typography } from "@mui/material";
import { ProjectContext, ProjectDispatchContext } from "pages/project/ProjectContext";
import { useContext, useState } from "react";
import { ChangeTypes, ModelTypes } from "types/SharedTypes";
import RegexExpressions from "util/RegexExpressions";

export interface ModelCreateDialogProps extends DialogProps {
  handleClose: () => void;
}

export default function ModelCreateDialog(props: ModelCreateDialogProps) {
  const projectContext = useContext(ProjectContext);
  const dispatch = useContext(ProjectDispatchContext);
  const project = projectContext?.displaySpec;
  const [modelName, setModelName] = useState("");
  const existingModelNames = project?.hostSpec.models.asJsReadonlyArrayView().map((m) => m.name) || [];
  const nameIsUnique = !existingModelNames.includes(modelName);
  const nameIsPascalCase = RegexExpressions.pascalCase.test(modelName);
  const nameIsValid = nameIsPascalCase && nameIsUnique;
  let errorMessage: string | null = null;
  if (!nameIsPascalCase && modelName.length > 0) {
    errorMessage = "Please enter a name in PascalCase format.";
  } else if (!nameIsUnique) {
    errorMessage = "A model with this name already exists.";
  }

  if (!project) {
    return null;
  }

  return (
    <Dialog {...props} onClose={() => { props.handleClose() }} maxWidth="md" fullWidth={true}>
      <Card>
        <Stack p={4} spacing={2}>
          <Typography variant="h5">Create a model</Typography>
          <TextField autoComplete="off" label="Model Name" variant="outlined" error={errorMessage !== null} helperText={errorMessage} 
          value={modelName} onChange={(e) => setModelName(e.target.value)} />
          <Button variant="contained" color="primary" disabled={!nameIsValid} onClick={() => {
            dispatch(new ChangeTypes.Change.HostSpecChange.CreateModelSpecChange(project.id, modelName, ModelTypes.utils.generateUuid()));
            props.handleClose();
          }}>Create</Button>
        </Stack>
      </Card>
    </Dialog>
  );
}