import { Button, Grid2 as Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { JwtPayload, jwtDecode } from "jwt-decode";
import { RoundedCornerStack } from 'components/base/RoundedCornerStack';
import { on } from 'events';


declare global {
  interface Window {
    google: any;
  }
}

export default function LoginPage({
  onLogin,
}: {
  onLogin: (token: string) => void;
}) {
  const googleLoginRef = React.useRef<HTMLDivElement>(null);
  const [email, setEmail] = React.useState<string | null>(null);

  useEffect(() => {
    if (!window.google) {
      return;
    }
    const handleCredentialResponse = (response: any) => {
      if (!response || !response.credential) {
        console.error('Google login failed: No credential received.');
        return;
      }
      onLogin(response.credential);
    };

    window.google.accounts.id.initialize({
      client_id: '967041844890-qq26o3bo8cgm6m5brouuqkirgpnm43t4.apps.googleusercontent.com',
      callback: handleCredentialResponse,
      auto_select: true,
    });

    window.google.accounts.id.renderButton(
      googleLoginRef.current,
      {}
    );

    window.google.accounts.id.prompt();
  }, [googleLoginRef]);

  return (
    <Grid container sx={{
      height: "100vh",
      alignItems: "center",
    }}>
      <Grid size={4} offset={4}>
        <RoundedCornerStack p={2} spacing={1}>
          <Typography variant="h6" align='center'>bend<b>scale</b></Typography>
          <div className="g_id_signin"
            style={{ display: 'flex', justifyContent: 'center' }}
            ref={googleLoginRef}
            data-type="standard"
            data-shape="rectangular"
            data-theme="outline"
            data-text="signin_with"
            data-size="large"
            data-logo_alignment="center">
          </div>
        </RoundedCornerStack>
      </Grid>
    </Grid>
  )
};