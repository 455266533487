package com.backit.shared.types.host.endpoint

import com.backit.shared.types.host.utils.generateUuid
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlin.js.JsExport

@JsExport
@Serializable
data class EndpointSpec(
    val id: String = generateUuid(),
    val path: String,
    val params: List<EndpointParamSpec>,
    val selection: EndpointSelectionSpec,
    val filter: EndpointFilterSpec? = null,
)

@JsExport
fun serializeEndpointSpec(endpointSpec: EndpointSpec): String {
    return Json.encodeToString(endpointSpec)
}
