package com.backit.shared.types.host.changes.handlers

import com.backit.shared.types.host.ModelSpec
import com.backit.shared.types.host.changes.Change.ModelSpecChange.ModelSpecAddColumnChange

internal fun ModelSpec.applyModelSpecAddColumnChange(
    change: ModelSpecAddColumnChange,
): ModelSpec {
    return copy(
        columns = columns + change.columnSpec
    )
}
