import { Grid2 as Grid, Stack, Typography, ToggleButtonGroup, ToggleButton, Select, MenuItem, TextField } from "@mui/material";
import React, { useContext } from "react";
import NullablePicker from "./ColumnSpecEditorNullablePicker.tsx";
import { ChangeTypes, ModelTypes } from "types/SharedTypes.tsx";
import { ChangeHandler } from 'types/ChangeHandler.tsx'
import { ColumnSpecCreationEditorProps } from "./ColumnSpecCreationEditor.tsx";
import { ProjectContext } from "pages/project/ProjectContext.tsx";

export default function ReferenceColumnSpecCreationEditor(props: ColumnSpecCreationEditorProps<ModelTypes.columnspecs.ReferenceColumnSpec>) {
  const project = useContext(ProjectContext);
  const [defaultValue, setDefaultValue] = React.useState(props.columnSpec.default?.toString());

  if (!project) {
    return;
  }

  const {
    projectId,
    modelId,
    columnSpec,
    applyChange,
  } = props;

  const tableSelect = project.displaySpec.hostSpec.models.asJsReadonlyArrayView().map((model) => {
    return <MenuItem key={model.id} value={model.name}>{model.name}</MenuItem>
  });

  return (
    <Grid container spacing={2}>
      <Grid size={6}>
        <NullablePicker {...props} />
      </Grid>
      <Grid size={6}>
        <Stack spacing={1}>
          <Typography variant="caption">Default Value</Typography>
          <ToggleButtonGroup
            color="primary"
            value={columnSpec.default !== null}
            exclusive
            onChange={(
              _event: React.MouseEvent<HTMLElement>,
              value: boolean | null
            ) => {
              if (value !== null) {
                if (value) {
                  setDefaultValue("0.0");
                }
                applyChange(new ChangeTypes.Change.ReferenceColumnSpecChange.ReferenceColumnSpecDefaultChange(projectId, modelId, columnSpec.id, value ? 0.0 : null));
              }
            }}
          >
            <ToggleButton value={false}>No Default Value</ToggleButton>
            <ToggleButton value={true}>Set Default Value</ToggleButton>
          </ToggleButtonGroup>
          {columnSpec.default !== null && (
            <TextField
              value={columnSpec.default}
              type="number"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setDefaultValue(event.target.value);
                var value = parseInt(event.target.value);
                if (isNaN(value)) {
                  value = 0;
                }
                applyChange(new ChangeTypes.Change.ReferenceColumnSpecChange.ReferenceColumnSpecDefaultChange(projectId, modelId, columnSpec.id, value));
              }}
            />)}

          <Typography variant="caption">Table to reference</Typography>
          <Select value={columnSpec.table} onChange={(event) => {
            applyChange(new ChangeTypes.Change.ReferenceColumnSpecChange.ReferenceColumnSpecTableChange(projectId, modelId, columnSpec.id, event.target.value as string));
          }}>
            {tableSelect}
          </Select>
        </Stack>
      </Grid>
      <Grid size="grow" />
    </Grid>
  );
}