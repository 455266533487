package com.backit.shared.types.host.endpoint

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@JsExport
@Serializable
sealed interface EndpointFilterSpec

@JsExport
@SerialName("or")
@Serializable
data class OrEndpointFilterSpec(
    val filters: List<EndpointFilterSpec>
) : EndpointFilterSpec

@JsExport
@SerialName("and")
@Serializable
data class AndEndpointFilterSpec(
    val filters: List<EndpointFilterSpec>
) : EndpointFilterSpec

@JsExport
@SerialName("eq")
@Serializable
data class EqualsEndpointFilterSpec(
    val table: String,
    val column: String,
    val value: FilterValueSpec
) : EndpointFilterSpec
