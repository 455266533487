package com.backit.shared.types.api.request

import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlin.js.JsExport

@Serializable
@JsExport
sealed interface Request


@JsExport
fun Request.serialize(): String {
    return Json.encodeToString(this)
}
