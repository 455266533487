package com.backit.shared.types.host

import com.backit.shared.types.host.changes.Change
import com.backit.shared.types.host.endpoint.EndpointSpec
import com.backit.shared.types.host.utils.generateUuid
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlin.js.JsExport

@JsExport
@Serializable
data class HostSpec(
    val models: List<ModelSpec>,
    val endpoints: List<EndpointSpec>,
)

@JsExport
@Serializable
data class ProjectSpec(
    val id: String = generateUuid(),
    val name: String,
    val hostSpec: HostSpec,
)


@JsExport
fun serializeProjectSpec(projectSpec: ProjectSpec): String {
    return Json.encodeToString(projectSpec)
}

@JsExport
fun serializeChange(change: Change): String {
    return Json.encodeToString(change)
}

@JsExport
fun serializeChangeList(changes: List<Change>): String {
    return Json.encodeToString(changes)
}
