package com.backit.shared.types.core

import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@JsExport
@Serializable
data class User(
    val id: String,
    val firstName: String,
    val lastName: String,
    val organizationId: String,
)
