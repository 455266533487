import { Stack, Typography, ToggleButtonGroup, ToggleButton } from "@mui/material";
import React from "react";
import { ChangeTypes, ModelTypes } from "types/SharedTypes.tsx";
import { ColumnSpecCreationEditorProps } from "./ColumnSpecCreationEditor";

export default function NullablePicker({
  projectId,
  columnSpec,
  applyChange,
  modelId
}: ColumnSpecCreationEditorProps<ModelTypes.columnspecs.ColumnSpec>) {
  return <Stack spacing={1}>
    <Typography variant="caption">Nullable</Typography>
    <ToggleButtonGroup
      color="primary"
      value={columnSpec.nullable}
      exclusive
      onChange={(
        _event: React.MouseEvent<HTMLElement>,
        value: boolean
      ) => {
        if (value !== null) {
          applyChange(new ChangeTypes.Change.ColumnSpecChange.ColumnSpecNullableChange(projectId, modelId, columnSpec.id, value));
        }
      }}
      aria-label="Platform"
    >
      <ToggleButton value={true}>Nullable</ToggleButton>
      <ToggleButton value={false}>Not Nullable</ToggleButton>
    </ToggleButtonGroup>
  </Stack>;
}