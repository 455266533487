package com.backit.shared.types.host

import com.backit.shared.types.host.columnspecs.ColumnSpec
import com.backit.shared.types.host.utils.generateUuid
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlin.js.JsExport

@JsExport
@Serializable
data class ModelSpec(
    val id: String = generateUuid(),
    val name: String,
    val columns: List<ColumnSpec>
)

@JsExport
fun ModelSpec.serialize(): String {
    return Json.encodeToString(this)
}

@JsExport
fun <T> toList(array: Array<T>): List<T> = array.toList()
